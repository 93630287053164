import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const TenantFooter = props => {
  const tenantLinks = [
    {
      url: props.howItWorks,
      locoKey: 'footer.item.how_it_works'
    },
    {
      url: props.blog,
      locoKey: 'footer.item.blog'
    },
    {
      url: props.help,
      locoKey: 'footer.item.help'
    },
    {
      url: props.promotions,
      locoKey: 'footer.item.promotions'
    },
    {
      url: props.contactUs,
      locoKey: 'footer.item.contact_us'
    }
  ];

  return (
    <Fragment>
      <FooterBlockTitle text={trans('footer.label.tenants')} theme="dark" />
      <FooterBlockList>
        {tenantLinks.map(({ locoKey, url }) => (
          <FooterLink key={locoKey} linkTheme={props.linkTheme} href={url}>
            {trans(locoKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </Fragment>
  );
};

TenantFooter.propTypes = {
  howItWorks: PropTypes.string.isRequired,
  blog: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired,
  promotions: PropTypes.string.isRequired,
  contactUs: PropTypes.string.isRequired,
  linkTheme: PropTypes.string.isRequired
};

export default TenantFooter;
