/* eslint-disable react/jsx-handler-names */
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';
import Metrics from '@spotahome/soyuz-tracking';

import { useHeaderLinks, useBaseLinks } from '../../../context/ExternalLink';

import MobileMenu from '../../MobileMenu';
import MobileMenuList from '../../MobileMenu/MobileMenuList';
import MobileMenuItem from '../../MobileMenu/MobileMenuItem';
import MobileMenuLink from '../../MobileMenu/MobileMenuLink';
import Separator from '../../MobileMenu/Separator';
import Logo from '../../../../base/Logo';
import LanguageSwitcher from '../../LanguageSwitcher';
import HeaderLogin from '../HeaderLogin';

import linkShape from '../shapes/link.shape';
import loginShape from '../shapes/login.shape';

const HeaderNavMobile = ({
  locales,
  login,
  onClose = () => {},
  onAppClick = () => {},
  showLogin,
  isContactUsEnabled = false,
  ...links
}) => {
  const headerLinks = useHeaderLinks();
  const localeLinks = useBaseLinks();
  const {
    home = {},
    listYourProperty = {},
    tenantLandingPage,
    howItWorks = {},
    favorites = {},
    changeCity = false,
    help = {},
    logo = {}
  } = links;

  const handleChangeCity = e => {
    Metrics.ga.sendEvent('mobile-menu', 'Change city');
    if (changeCity.onClick && typeof changeCity.onClick === 'function') {
      changeCity.onClick(e);
    }
  };

  return (
    <MobileMenu
      logo={
        <a href={headerLinks.landing} {...logo}>
          <Logo size="small" />
        </a>
      }
      onMobileAppButtonClick={onAppClick}
      onClose={onClose}
    >
      <MobileMenuList>
        {!!home && (
          <MobileMenuItem>
            <MobileMenuLink
              className="ga-home"
              href={headerLinks.landing}
              {...links.home}
            >
              {home.label || trans('landing-pages.home')}
            </MobileMenuLink>
          </MobileMenuItem>
        )}

        {!!listYourProperty && (
          <MobileMenuItem>
            <MobileMenuLink
              className="publish-your-property-mobile"
              href={headerLinks.publishYourProperty}
              {...listYourProperty}
            >
              {listYourProperty.label || trans('landingpage.button.landlord')}
            </MobileMenuLink>
          </MobileMenuItem>
        )}

        {!!tenantLandingPage && (
          <MobileMenuItem>
            <MobileMenuLink
              className="tenant-landing-page-mobile"
              href={headerLinks.landing}
              {...tenantLandingPage}
            >
              {tenantLandingPage.label || trans('landingpage.button.tenant')}
            </MobileMenuLink>
          </MobileMenuItem>
        )}

        {!!howItWorks && (
          <MobileMenuItem>
            <MobileMenuLink
              className="faqs-mobile"
              target="_blank"
              href={headerLinks.howItWorks}
              {...howItWorks}
            >
              {howItWorks.label || trans('how_it_works.title')}
            </MobileMenuLink>
          </MobileMenuItem>
        )}

        {!!favorites && (
          <MobileMenuItem>
            <MobileMenuLink
              href={headerLinks.favorites}
              target="_blank"
              rel="nofollow"
              {...favorites}
            >
              {favorites.label || trans('favorites.title.favorites')}
            </MobileMenuLink>
          </MobileMenuItem>
        )}

        {!!changeCity && (
          <MobileMenuItem>
            <MobileMenuLink
              className="change-city"
              href={headerLinks.landing}
              {...changeCity}
              onClick={handleChangeCity}
            >
              {changeCity.label || trans('change_city.title')}
            </MobileMenuLink>
          </MobileMenuItem>
        )}

        <Separator />
        {showLogin || !!login ? <HeaderLogin {...login} isMobile /> : null}
        <Separator />

        {help && (
          <MobileMenuItem>
            <MobileMenuLink href={headerLinks.support} {...help}>
              {help.label || trans('help.title')}
            </MobileMenuLink>
          </MobileMenuItem>
        )}

        {isContactUsEnabled && (
          <MobileMenuItem>
            <MobileMenuLink href={headerLinks.contactUs}>
              {trans('menu.contactus')}
            </MobileMenuLink>
          </MobileMenuItem>
        )}

        <MobileMenuItem>
          <MobileMenuLink>
            <LanguageSwitcher
              theme="dark"
              locales={Object.keys(localeLinks) || locales.list}
              current={locales.current}
              onChange={locales.onChange}
            />
          </MobileMenuLink>
        </MobileMenuItem>
      </MobileMenuList>
    </MobileMenu>
  );
};

HeaderNavMobile.propTypes = {
  logo: PropTypes.shape(linkShape),
  home: PropTypes.shape(linkShape),
  landlordPanel: PropTypes.shape(linkShape),
  listYourProperty: PropTypes.shape(linkShape),
  howItWorks: PropTypes.shape(linkShape),
  favorites: PropTypes.shape(linkShape),
  help: PropTypes.shape(linkShape),
  changeCity: PropTypes.shape(linkShape),
  locales: PropTypes.shape({
    current: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired
  }).isRequired,
  login: PropTypes.shape(loginShape).isRequired,
  onClose: PropTypes.func,
  onAppClick: PropTypes.func,
  isContactUsEnabled: PropTypes.bool,
  showLogin: PropTypes.bool.isRequired
};

export default HeaderNavMobile;
