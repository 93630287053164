export default {
  aboutUs: {
    en: '/about-us',
    es: '/es/sobre-nosotros',
    it: '/it/chi-siamo',
    fr: '/fr/a-propos-de-nous',
    de: '/de/uber-uns',
    tr: '/tr/hakkimizda',
    pl: '/pl/o-nas',
    pt: '/pt/sobre-nos'
  },
  collaborators: {
    en: '/collaborators',
    es: '/es/collaborators',
    it: '/it/collaborators',
    fr: '/fr/collaborators',
    de: '/de/collaborators',
    tr: '/tr/collaborators',
    pl: '/pl/collaborators',
    pt: '/pt/collaborators'
  },
  becomePartner: {
    en: '/become-a-partner-of-spotahome',
    es: '/es/become-a-partner-of-spotahome',
    it: '/it/become-a-partner-of-spotahome',
    fr: '/fr/become-a-partner-of-spotahome',
    de: '/de/become-a-partner-of-spotahome',
    tr: '/tr/become-a-partner-of-spotahome',
    pl: '/pl/become-a-partner-of-spotahome',
    pt: '/pt/become-a-partner-of-spotahome'
  },
  institutions: {
    en: '/institutions',
    es: '/es/institutions',
    it: '/it/institutions',
    fr: '/fr/institutions',
    de: '/de/institutions',
    tr: '/tr/institutions',
    pl: '/pl/institutions',
    pt: '/pt/institutions'
  },
  workWithUs: {
    en: '/work-with-us',
    es: '/es/work-with-us',
    it: '/it/work-with-us',
    fr: '/fr/work-with-us',
    de: '/de/work-with-us',
    tr: '/tr/work-with-us',
    pl: '/pl/work-with-us',
    pt: '/pt/work-with-us'
  },
  howItWorks: {
    en: '/how-it-works',
    es: '/es/como-funciona',
    it: '/it/come-funziona',
    fr: '/fr/comment-fonctionne',
    de: '/de/wie-es-funktioniert',
    tr: '/tr/nasil-calisiyor',
    pl: '/pl/jak-to-dziala',
    pt: '/pt/como-funciona'
  },
  internships: {
    en: '/internships',
    es: '/es/internships',
    it: '/it/internships',
    fr: '/fr/internships',
    de: '/de/internships',
    tr: '/tr/internships',
    pl: '/pl/internships',
    pt: '/pt/work-with-us'
  },
  studentAmbassador: {
    en: 'https://rent.spotahome.com/become-a-spotahome-ambassador',
    es: 'https://rent.spotahome.com/become-a-spotahome-ambassador',
    it: 'https://rent.spotahome.com/become-a-spotahome-ambassador',
    fr: 'https://rent.spotahome.com/become-a-spotahome-ambassador',
    de: 'https://rent.spotahome.com/become-a-spotahome-ambassador',
    tr: 'https://rent.spotahome.com/become-a-spotahome-ambassador',
    pl: 'https://rent.spotahome.com/become-a-spotahome-ambassador',
    pt: 'https://rent.spotahome.com/become-a-spotahome-ambassador'
  },
  termsAndConditions: {
    en: '/terms-and-conditions',
    es: '/es/terminos-y-condiciones',
    it: '/it/termini-e-condizioni',
    fr: '/fr/termes-et-conditions',
    de: '/de/geschaftsbedingungen',
    tr: '/tr/sartlar-ve-kosullar',
    pl: '/terms-and-conditions',
    pt: '/terms-and-conditions'
  },
  companyDetails: {
    en: '/company-details',
    es: '/es/datos-empresa',
    it: '/it/informazioni-di-contatto',
    fr: '/fr/coordonnees-entreprise',
    de: '/de/impressum ',
    pt: '/pt/detalhes-empresa',
    pl: '/company-details',
    tr: '/company-details'
  },
  cookiesPolicy: {
    en: '/cookies-policy',
    es: '/es/politica-cookies',
    it: '/it/cookies-policy',
    fr: '/fr/cookies-policy',
    de: '/de/cookies-policy',
    tr: '/tr/cookies-policy',
    pl: '/pl/cookies-policy',
    pt: '/pt/cookies-policy'
  },
  privacyPolicies: {
    en: '/privacy-policies',
    es: '/es/politica-privacidad',
    it: '/it/privacy-policies',
    fr: '/fr/privacy-policies',
    de: '/de/privacy-policies',
    tr: '/tr/privacy-policies',
    pl: '/pl/privacy-policies',
    pt: '/pt/privacy-policies'
  },
  sitemap: {
    en: '/sitemap',
    es: '/es/sitemap',
    it: '/it/sitemap',
    fr: '/fr/sitemap',
    de: '/de/sitemap',
    tr: '/tr/sitemap',
    pl: '/pl/sitemap',
    pt: '/pt/sitemap'
  },
  blog: {
    en: '/blog/',
    es: '/es/blog/',
    it: '/blog/',
    fr: '/fr/blog/',
    de: '/de/blog/',
    tr: '/blog/',
    pl: '/blog/',
    pt: '/blog/'
  },
  help: {
    de: 'https://spotahome.zohodesk.com/portal/de/kb/about-spotahome/mieter',
    es: 'https://spotahome.zohodesk.com/portal/es/kb/about-spotahome/inquilino',
    fr: 'https://spotahome.zohodesk.com/portal/fr/kb/about-spotahome/locataire',
    it: 'https://spotahome.zohodesk.com/portal/it/kb/about-spotahome/inquilino',
    en: 'https://spotahome.zohodesk.com/portal/en/kb/help-center/tenant',
    tr: 'https://spotahome.zohodesk.com/portal/en/kb/help-center/tenant',
    pl: 'https://spotahome.zohodesk.com/portal/en/kb/help-center/tenant',
    pt: 'https://spotahome.zohodesk.com/portal/pt/kb/about-spotahome/inquilino'
  },
  promotions: {
    en: '/promotions',
    es: '/es/promotions',
    it: '/it/promotions',
    fr: '/fr/promotions',
    de: '/de/promotions',
    tr: '/tr/promotions',
    pl: '/pl/promotions',
    pt: '/pt/promotions'
  },
  contactUs: {
    en: '/contact-us',
    es: '/es/contacto',
    it: '/it/contattaci',
    fr: '/fr/nous-contacter',
    de: '/de/kontaktieren-sie-uns',
    tr: '/tr/bize-ulasin',
    pl: '/pl/skontaktuj-sie-z-nami',
    pt: '/pt/contacte-nos'
  },
  publishYourProperty: {
    en: '/landlords',
    es: '/es/propietarios',
    it: '/it/proprietari',
    fr: '/fr/proprietaires',
    de: '/de/fuer-den-vermieter',
    tr: '/tr/ev-sahipleri',
    pl: '/pl/wlasciciele',
    pt: '/pt/publique-a-sua-propriedade'
  }
};
