import { trans } from '@spotahome/soyuz/client';

import TestimonialQuote from '@spotahome/landings-common/src/components/TestimonialQuote';
import { testimonialQuotes } from '@spotahome/marketplace-common/src/utils/testimonialQuotes';

import './CardTestimonialQuoteSection.scss';
import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

const CardTestimonialQuoteSection = () => (
  <section className="card-testimonial-quote-section">
    <h2 className="card-testimonial-quote-section__title">
      {trans('home.tenant.testimonial-quote.title')}
    </h2>
    <div className="card-testimonial-quote-section__wrapper">
      {testimonialQuotes.map(quote => (
        <div
          className="card-testimonial-quote-section__item"
          key={quote.message}
        >
          <TestimonialQuote
            theme="pink"
            message={trans(quote.message)}
            source={trans(quote.source)}
            insideCard
            quoteSize="medium"
          />
        </div>
      ))}
    </div>
  </section>
);

export default withInViewEvent(LANDING_SECTIONS.cardTestimonialQuote)(
  CardTestimonialQuoteSection
);
