import {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import {clientErrorTracker, useSoyuzLocales} from '@spotahome/soyuz/client';

import getGqlClient from '../clients/graphql/marketplace';

const QUERY_CITIES_TAG = gql`
  query Cities($locale: CityLocale!) {
    cities {
      id
      currencyIsoCode
      cityName(locale: $locale)
      country {
        countryId
      }
    }
  }
`;

const getCitiesQuery = client => locale =>
  client.query({
    query: QUERY_CITIES_TAG,
    fetchPolicy: 'cache-first',
    variables: {
      locale
    }
  });

const CitiesContext = createContext({
  cities: [],
  requestCities: () => {}
});

export const CitiesProvider = ({
  children,
  initialCityInfo = null,
  skipAllCities = false
}) => {
  const { current: currentLocale } = useSoyuzLocales();
  const [cities, setCities] = useState(
    initialCityInfo ? [initialCityInfo] : []
  );

  const requestCities = async () => {
    try {
      const citiesResponse = await getCitiesQuery(getGqlClient())(
        currentLocale
      );

      setCities(
        citiesResponse.data.cities.sort((a, b) => (a.id > b.id ? 1 : -1))
      );
    } catch (error) {
      clientErrorTracker.error({ error, source: 'CitiesProvider' });
      setCities(initialCityInfo ? [initialCityInfo] : []);
    }
  };

  useEffect(() => {
    if (!skipAllCities) {
      requestCities();
    }
  }, []);

  return (
    <CitiesContext.Provider value={{ cities, requestCities }}>
      {children}
    </CitiesContext.Provider>
  );
};

CitiesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  initialCityInfo: PropTypes.shape({
    id: PropTypes.string,
    currencyIsoCode: PropTypes.string,
    cityName: PropTypes.string
  }),
  skipAllCities: PropTypes.bool
};

export const useCitiesContext = () => useContext(CitiesContext);

export const useCityInfo = cityId => {
  const { cities } = useCitiesContext();
  return cities.find(city => city.id === cityId);
};

export const useCityName = cityId => {
  const selectedCity = useCityInfo(cityId);

  return selectedCity ? selectedCity.cityName : '';
};

export const useCityCurrency = cityId => {
  const selectedCity = useCityInfo(cityId);

  return selectedCity ? selectedCity.currencyIsoCode : 'EUR';
};
export default {
  CitiesProvider,
  useCitiesContext,
  useCityName,
  useCityCurrency,
  useCityInfo,
};
