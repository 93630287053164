const Lock = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 41H10V22.123C10 11.0281 18.973 2 30 2C41.027 2 50 11.0281 50 22.123L49.9999 41H40.9999L41 22.2576C41 16.0507 36.0649 11 30 11C23.9351 11 19 16.0507 19 22.2576V41Z"
      fill="#C9E3F7"
    />
    <rect x="4" y="26" width="52" height="34" fill="#0A0777" />
    <circle cx="30" cy="43" r="4" fill="#C9E3F7" />
  </svg>
);

export default Lock;
