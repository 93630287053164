import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';
import { Mediator } from '@spotahome/soyuz-mediator';

import { setItem } from '../../../utils/localStorage';

import { showOfferToast } from '../Toast/';

import {
  getDefaultOfferBoxInfo,
  getOfferBoxInfo,
  updateOfferBoxInfo,
  getRemainingOfferHours
} from '../utils/temporalOfferToast';

import {
  COMMON_LOGIN_MODAL,
  OPEN_MODAL_EVENTS,
  OFFER_MODAL_MUST_SHOW,
  DELAY_TOAST_IN_MS
} from '../utils/constants';

import styles from './TemporalOfferToast.module.scss';

const TemporalOfferToast = ({ autoClose, isModalOpen, delayToastInMs }) => {
  const handleCTAClick = () => {
    updateOfferBoxInfo({
      hasInteracted: true
    });

    setItem(OFFER_MODAL_MUST_SHOW, 1);

    Mediator.publish(COMMON_LOGIN_MODAL, {
      renderTitle: () => trans('listingpage.temporal.discount.title'),
      initialAction: 'signup',
      disableLoginSignUpToggle: true,
      events: OPEN_MODAL_EVENTS
    });
  };

  const handleOnClose = () => {
    updateOfferBoxInfo({
      hasInteracted: true
    });
  };

  useEffect(() => {
    let offerBoxStorageInfo = getOfferBoxInfo();

    // Store if its unitialized
    if (!offerBoxStorageInfo) {
      offerBoxStorageInfo = getDefaultOfferBoxInfo();
      updateOfferBoxInfo(offerBoxStorageInfo);
    }

    const remainingOfferHours = getRemainingOfferHours(offerBoxStorageInfo);

    if (
      !offerBoxStorageInfo.hasInteracted &&
      offerBoxStorageInfo.hasClosedSignupModal &&
      remainingOfferHours > 0
    ) {
      setTimeout(() => {
        showOfferToast(
          {
            renderTitle: () => (
              <span
                className={styles['temporal-offer-toast__link-description']}
              >
                {trans('listingpage.temporal.discount.link', {
                  remainingOfferHours
                })}
              </span>
            ),
            renderContent: () => (
              <span className={styles['temporal-offer-toast__link-discount']}>
                {trans('listingpage.temporal.discount.content')}
              </span>
            )
          },
          {
            className: 'temporal-offer-toast',
            bodyClassName: 'temporal-offer-toast__container',
            autoClose,
            onClick: handleCTAClick,
            onClose: handleOnClose
          }
        );
      }, delayToastInMs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return null;
};

TemporalOfferToast.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  autoClose: PropTypes.bool,
  delayToastInMs: PropTypes.number
};

TemporalOfferToast.defaultProps = {
  autoClose: false,
  delayToastInMs: DELAY_TOAST_IN_MS
};

export default TemporalOfferToast;
