import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { trans, useSoyuzLocales, useSoyuzFlag } from '@spotahome/soyuz/client';
import { LoginSignUpModal } from '@spotahome/ui-library';
import { Mediator } from '@spotahome/soyuz-mediator';
import Metrics from '@spotahome/soyuz-tracking';
import { ZENDESK_SUPPORT } from '@spotahome/customer-graphql-modules/dist/constants';

import graphqlClient from '../../graphql/client';

import {
  traditionalSignup,
  traditionalLogin,
  traditionalForgotPassword
} from '../../api/traditional-login-client';
import createZendeskTicket from '../../graphql/mutations/zendesk';

const reloadPage = () => {
  document.location.reload();
};

const scrollTop = () => window.scrollTo({ top: 0 });

const LoginModal = ({
  onTraditionalLoginSuccess,
  onTraditionalSignupSuccess
}) => {
  const { current: locale } = useSoyuzLocales();
  const [error, setError] = useState(null);
  const hideTraditionalAuth = useSoyuzFlag('HideTraditionalAuth').isOn();
  const hideFacebookOauth = useSoyuzFlag('HideFacebookOauth').isOn();
  const hideGoogleOauth = useSoyuzFlag('HideGoogleOauth').isOn();

  const handleTraditionalLogin = async ({ email, password }) => {
    try {
      setError(null);
      await traditionalLogin({ email, password });
      onTraditionalLoginSuccess();
    } catch (e) {
      setError(trans('login.error'));
      console.error(e);
    }
  };

  const handleTraditionalSignup = async ({
    name,
    email,
    password,
    marketingOptIn
  }) => {
    try {
      setError(null);
      await traditionalSignup({
        name,
        email,
        password,
        marketingOptIn,
        locale
      });
      onTraditionalSignupSuccess();
    } catch (e) {
      setError(trans('signup.error'));
      console.error(e);
    }
  };

  const handleForgottenPassword = async ({ email }) => {
    try {
      setError(null);
      await traditionalForgotPassword({ email });
    } catch (err) {
      setError(trans('forgotten-password.error'));
      console.error(err);
    }
  };

  const handleHelpFormSubmit = values => {
    Metrics.ga.sendEvent('contact_form_revamp', 'form_send', 'attempt');

    const { name, email, phoneNumber, comment } = values;

    setError(null);
    return createZendeskTicket(graphqlClient())({
      input: {
        reason: ZENDESK_SUPPORT.TENANT_REASONS.TENANT_ACCOUNT_ACCESS,
        role: 'tenant',
        name,
        email,
        phoneNumber,
        comment
      }
    })
      .then(() => {
        scrollTop();
        Metrics.ga.sendEvent('contact_form_revamp', 'form_send', 'success');
      })
      .catch(err => {
        console.log(err);
        Metrics.ga.sendEvent('contact_form_revamp', 'form_send', 'error');
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(document.location.search);
    if (queryParams.get('target') === 'recover') {
      const initialEmail = queryParams.get('email');
      Mediator.publish('AUTH_MODAL_OPEN', {
        initialAction: 'forgotten_password',
        initialValues: {
          email: initialEmail
        },
        events: {
          open: {
            eventCategory: 'Facebook recovery',
            eventAction: 'Open recover password',
            eventLabel: 'open'
          },
          close: {
            eventCategory: 'Facebook recovery',
            eventAction: 'Close recover password',
            eventLabel: 'close'
          }
        }
      });
    }
  }, []);

  return (
    <LoginSignUpModal
      locale={locale}
      onTraditionalLogin={handleTraditionalLogin}
      onTraditionalSignup={handleTraditionalSignup}
      onForgottenPassword={handleForgottenPassword}
      onHelpFormSubmit={handleHelpFormSubmit}
      error={error}
      enableTemporalOfferToast
      hideTraditionalAuth={hideTraditionalAuth}
      hideFacebookOauth={hideFacebookOauth}
      hideGoogleOauth={hideGoogleOauth}
    />
  );
};

LoginModal.propTypes = {
  onTraditionalLoginSuccess: PropTypes.func,
  onTraditionalSignupSuccess: PropTypes.func
};

LoginModal.defaultProps = {
  onTraditionalLoginSuccess: reloadPage,
  onTraditionalSignupSuccess: reloadPage
};

export default LoginModal;
