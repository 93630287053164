/* eslint-disable jsx-a11y/interactive-supports-focus */
import { lazy, Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import NoSSR from 'react-no-ssr';

import classNames from 'classnames';

import { clientErrorTracker, useSoyuzLocales } from '@spotahome/soyuz/client';
import Metrics from '@spotahome/soyuz-tracking';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useUserInfoContext } from '../../context/UserInfoContext';
import { useCitiesContext } from '../../context/CitiesContext';
import { useCookie } from '../utils/withCookie';
import { useModalHandle } from '../utils/withModalHandle';

import getGqlClient from '../../clients/graphql/landings';

import { VALUE_PROPOSITION, INTRODUCTION } from './constants';
import { sendInviteEvent, emailSignupWithoutPassword } from './api';

import styles from './SpottyCat.module.scss';

const SpottyCatVPModal = lazy(() => import('./components/SpottyCatVPModal'));
const SpottyCatModal = lazy(() => import('./components/SpottyCatModal'));

const cx = classNames.bind(styles);

const TWO_WEEKS = 60 * 60 * 24 * 14; // 2 weeks in seconds

const SPOTTY_CAT_IMG =
  'https://assets.spotahome.com/static/spotty-rebranding.png';

const SpottyCat = () => {
  const { userInfo: user, isUserLogged } = useUserInfoContext();
  const { cities, requestCities } = useCitiesContext();
  const { current: locale } = useSoyuzLocales();
  const [step, setStep] = useState(VALUE_PROPOSITION);
  const { getCookie, setCookie } = useCookie();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModalHandle();

  const hasUsedSpottyCat = getCookie('SpottyCatUsed') !== undefined;

  const handleSpottyCatClose = () => {
    Metrics.ga.sendEvent('SpottyCat', 'Close', 'click');
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'Close',
      value: 'click'
    });
    handleModalClose();
    setCookie('SpottyCatUsed', 1, { maxAge: TWO_WEEKS });
    setStep(VALUE_PROPOSITION);
  };

  const handleNextStep = () => {
    setTimeout(() => {
      const nextStep = step + 1;
      Metrics.ga.sendEvent('SpottyCat', 'NextStep', nextStep);
      SoyuzAnalytics.sendGA4Event('SpottyCat', {
        action: 'NextStep',
        step: nextStep
      });
      setStep(nextStep);
    }, 500);
  };

  const handlePrevStep = () => {
    Metrics.ga.sendEvent('SpottyCat', 'Back', 'click');
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'Back',
      value: 'click'
    });
    setStep(step - 1);
  };

  const handleValuePropOpen = () => {
    handleModalOpen();
    Metrics.ga.sendEvent('SpottyCat', 'OpenCat', 'open-click');
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'OpenCat',
      value: 'open-click'
    });
  };

  const handleValuePropClose = () => {
    handleModalClose();
    Metrics.ga.sendEvent('SpottyCat', 'ValuePropClose', 'click');
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'ValuePropClose',
      value: 'click'
    });
    setCookie('SpottyCatUsed', 1, { maxAge: TWO_WEEKS });
  };

  const handleValuePropNext = () => {
    handleModalClose();
    setStep(INTRODUCTION);
    requestCities();
    Metrics.ga.sendEvent('SpottyCat', 'OpenForm', 'open-click');
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'OpenForm',
      value: 'open-click'
    });
  };

  const spottyCatImageClasses = cx(styles['spotty-cat__image'], {
    [styles['spotty-cat__image--hidden']]: hasUsedSpottyCat
  });

  const notificationClasses = cx(styles['spotty-cat__notification'], {
    [styles['spotty-cat__notification--hidden']]: hasUsedSpottyCat
  });

  const handleSignupWithoutPass = async ({ email, marketingOptIn }) => {
    if (!isUserLogged) {
      await emailSignupWithoutPassword({
        email,
        marketingOptIn,
        locale
      });
    }
  };

  const handleInviteEvent = async payload => {
    try {
      sendInviteEvent(getGqlClient())({ input: payload });
    } catch (error) {
      clientErrorTracker.error({ error, source: 'SpottyCatModal' });
    }
  };

  return (
    <NoSSR>
      <div onClick={handleValuePropOpen} role="button">
        <LazyLoad once>
          <img
            src={SPOTTY_CAT_IMG}
            alt="spotty cat"
            className={spottyCatImageClasses}
          />
        </LazyLoad>
        <span className={notificationClasses}>1</span>
      </div>

      {isModalOpen && (
        <Suspense fallback={null}>
          <SpottyCatVPModal
            isOpen={isModalOpen}
            onClose={handleValuePropClose}
            onNextStep={handleValuePropNext}
          />
        </Suspense>
      )}
      {step > VALUE_PROPOSITION && (
        <Suspense fallback={null}>
          <SpottyCatModal
            isOpen={step > VALUE_PROPOSITION}
            step={step}
            isUserLogged={isUserLogged}
            user={user}
            cities={cities}
            onClose={handleSpottyCatClose}
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
            onSuccess={handleInviteEvent}
            onSignupWithoutPassword={handleSignupWithoutPass}
          />
        </Suspense>
      )}
    </NoSSR>
  );
};

SpottyCat.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SpottyCat;
