import PropTypes from 'prop-types';

const ShieldPersonIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="45"
    fill="none"
    viewBox="0 0 39 45"
    className={props.className}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M36.431 7.703v19.079c0 2.749-2.068 5.932-5.825 8.963-3.195 2.577-7.338 4.851-11.106 6.084-3.768-1.233-7.911-3.507-11.106-6.084-3.757-3.031-5.825-6.214-5.825-8.963V7.702L19.5 2.63l16.931 5.073zm1.651-2.183L19.867.053c-.24-.07-.495-.07-.736 0L.916 5.52A1.294 1.294 0 0 0 0 6.753v20.029c0 4.638 3.864 8.64 7.105 11.187 4.216 3.304 9.107 5.538 12.014 6.437.122.04.25.06.381.06.132 0 .26-.02.38-.06 2.909-.9 7.8-3.133 12.014-6.437C35.136 35.422 39 31.42 39 26.782V6.753c0-.576-.369-1.071-.918-1.233z"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M23.15 16.118a3.66 3.66 0 0 1-3.658 3.658 3.66 3.66 0 0 1-3.657-3.658 3.666 3.666 0 0 1 3.657-3.658 3.666 3.666 0 0 1 3.658 3.658zm3.48 6.62a9.993 9.993 0 0 0-3.13-2.133 6.006 6.006 0 0 0 2.007-4.487 6.021 6.021 0 0 0-6.015-6.012 6.02 6.02 0 0 0-6.014 6.012 6.01 6.01 0 0 0 2.007 4.487 10.01 10.01 0 0 0-3.131 2.132 10.043 10.043 0 0 0-2.956 7.135v.424h2.357v-.424c0-4.265 3.47-7.73 7.737-7.73 4.268 0 7.739 3.465 7.739 7.73v.424h2.357v-.424a10.04 10.04 0 0 0-2.957-7.135z"
      clipRule="evenodd"
    />
  </svg>
);

ShieldPersonIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

ShieldPersonIcon.defaultProps = {
  className: '',
  color: '#32B18F'
};

export default ShieldPersonIcon;
