import PropTypes from 'prop-types';
import classNames from 'classnames';

import { trans } from '@spotahome/soyuz-trans/client';
import Tracking from '@spotahome/soyuz-tracking';

import { MOBILE_APP_BUTTONS } from './utils';
import MobileAppButton from './MobileAppButton';

import styles from './MobileMenu.module.scss';

const cx = classNames.bind(styles);

const MobileMenu = props => {
  const mobileMenuClassnames = cx(
    styles.mobileMenu,
    styles[props.color],
    props.className
  );

  const iconClassnames = cx(styles.closeIcon, 'icon');

  const handleClose = () => {
    props.onClose();
    Tracking.ga.sendEvent('MobileNavigationBar', 'Menu', 'Close');
  };

  return (
    <div className={mobileMenuClassnames}>
      <div className={styles.headWrapper}>
        {props.logo}
        <button
          type="button"
          className={styles.closeButton}
          onClick={handleClose}
        >
          <i className={iconClassnames} />
        </button>
      </div>
      <div className={styles.content}>{props.children}</div>
      <div className={styles.footer}>
        <strong className={styles.footer_title}>{trans('menu.footer')}</strong>
        <div className={styles.footer_actions}>
          {Object.keys(MOBILE_APP_BUTTONS).map(key => (
            <MobileAppButton
              key={key}
              {...MOBILE_APP_BUTTONS[key]}
              onClick={() => props.onMobileAppButtonClick(key)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MobileMenu.defaultProps = {
  color: 'dark',
  className: '',
  logo: '',
  children: undefined,
  onClose: () => {},
  onMobileAppButtonClick: () => {}
};

MobileMenu.propTypes = {
  color: PropTypes.oneOf(['dark', 'light']),
  className: PropTypes.string,
  logo: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onMobileAppButtonClick: PropTypes.func
};

export default MobileMenu;
