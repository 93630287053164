/* global dataLayer */
import Metrics from '@spotahome/soyuz-tracking';

export const addDataLayerGAEvent = (eventCategory, eventAction, eventLabel) => {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      event: 'eventga',
      eventAction,
      eventCategory,
      eventLabel
    });
  } else {
    Metrics.ga.sendEvent(eventCategory, eventAction, eventLabel);
  }
};

export default { addDataLayerGAEvent };
