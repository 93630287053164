import { trans, getPagePath } from '@spotahome/soyuz/client';
import { Button, MediaQuery } from '@spotahome/ui-library';

import { addDataLayerGAEvent } from '@spotahome/landings-common/src/utils/DataLayerGAHandler';
import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import './HomeFooter.scss';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

const HomeFooter = () => {
  const handleGetStartedButton = () => {
    addDataLayerGAEvent('Tenant', 'clickBtn', 'mainFooterBtn');
  };
  const { isMobileScreen, isPhabletScreen, isTabletScreen } = MediaQuery;
  const isMobileOrTablet =
    isMobileScreen() || isPhabletScreen() || isTabletScreen();

  return (
    <section className="home-footer-tenant__container">
      <div>
        <h2 className="home-footer-tenant__title">
          {trans('home.tenant.home-footer.title')}
        </h2>
        <div className="home-footer-tenant__subtitle">
          {trans('home.tenant.home-footer.subtitle')}
        </div>
      </div>

      <Button
        className="home-footer-tenant__button"
        size="big"
        color="primary"
        fullWidth={isMobileOrTablet}
        href={getPagePath('externals.landlord-signup')}
        onClick={handleGetStartedButton}
      >
        {trans('home.tenant.home-footer.cta')}
      </Button>
    </section>
  );
};

export default withInViewEvent(LANDING_SECTIONS.homeFooter)(HomeFooter);
