export const TRACKING_PAGE_TYPES = {
  SEARCH_PAGE: 'search',
  PRODUCT_PAGE: 'product_page',
  FAVORITES: 'favorites',
  MY_ALERTS: 'my_alerts',
  SEO_LANDING_PAGE: 'seo_landing_page',
  LOCATION_PAGE: 'location_page',
  HOMEPAGE: 'homepage',
  MY_REQUESTS_PAGE: 'my_requests',
  MY_PROFILE_PAGE: 'my_profile',
  SUPPORT_PAGE: 'support',
  MY_REFERRALS_PAGE: 'my_referrals',
  BOOKING_DETAILS_PAGE: 'booking_details',
  MY_SERVICES_PAGE: 'my_services',
  MY_PAYMENTS_PAGE: 'my_payments',
  BOOKING_CANCELLATION_PAGE: 'booking_cancellation',
  BOOKING_DATES_CHANGE_PAGE: 'booking_dates_change',
  PERSONAL_DETAILS_FORM: 'checkout_personal_information_form',
  ADDITIONAL_DETAILS_FORM: 'checkout_additional_information_form',
  PAYMENT_RESERVATION: 'checkout_payment_form',
  SUCCESSFUL_PAYMENT: 'payment_success'
};

export const LIST_E_COMMERCE = {
  EXTRA_ROOMS: 'extra-rooms',
  SIMILAR_PROPERTIES: 'similar-properties'
};

export const ECOMMERCE_STEPS = {
  bookingRequestButton: 1,
  personalDetails: 2,
  additionalDetails: 3,
  paymentMethod: 4,
  bookingRequest: 5
};
