import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import { useHeaderLinks } from '../../context/ExternalLink';

import HeaderLink from '../HeaderContainer/HeaderLink';
import HeaderItem from '../HeaderContainer/HeaderItem';
import Dropdown from '../Dropdown';
import DropdownItem from '../DropdownItem';
import SearchHomes from '../icons/SearchHomes';
import HomeKey from '../icons/HomeKey';

import styles from '../LoginButton/LoginButton.module.scss';

const NotLoggedDesktopButton = ({
  color,
  typeMenu,
  newHeaderSignupLogin,
  onHandleSignUp,
  onHandleLogIn
}) => {
  const headerLinks = useHeaderLinks();

  const renderSignUpLink = () => (
    <HeaderLink
      color={color}
      onClick={onHandleSignUp}
      data-test="signup-header-link"
      className="ga-signup-menu-button"
    >
      {trans('signup.text')}
    </HeaderLink>
  );

  const renderLoginLink = () => (
    <HeaderLink
      color={color}
      onClick={onHandleLogIn}
      data-test="login-header-link"
      className="ga-login-menu-button"
    >
      {trans('login.text')}
    </HeaderLink>
  );

  const renderLoginDropdown = () => {
    const landlordLoginClassName = `${styles['login-link']} ${styles['login-link__landlord']}`;

    return (
      <Dropdown
        title={trans('login.text')}
        position="right"
        color="light"
        textTransform="capitalize"
        className={typeMenu}
        arrowIcon
        dataTest="non-logged-in-menu"
      >
        <DropdownItem className="ga-tenant-login">
          <a className={styles['login-link']} onClick={onHandleLogIn}>
            <SearchHomes />
            {trans('login.text.tenant-login')}
          </a>
        </DropdownItem>
        <DropdownItem className="ga-landlord-login">
          <a
            className={landlordLoginClassName}
            href={headerLinks.landlordPanel}
          >
            <HomeKey />
            <span>{trans('login.text.landlord-login')}</span>
          </a>
        </DropdownItem>
      </Dropdown>
    );
  };

  return newHeaderSignupLogin ? (
    <Fragment>
      <HeaderItem>{renderSignUpLink()}</HeaderItem>
      <HeaderItem>{renderLoginDropdown()}</HeaderItem>
    </Fragment>
  ) : (
    <HeaderItem>
      {renderSignUpLink()}
      <span className={styles[color]}>{' | '}</span>
      {renderLoginLink()}
    </HeaderItem>
  );
};

NotLoggedDesktopButton.propTypes = {
  color: PropTypes.oneOf(['dark', 'light']).isRequired,
  typeMenu: PropTypes.oneOf(['menu-white', 'menu-transparent']).isRequired,
  newHeaderSignupLogin: PropTypes.bool.isRequired,
  onHandleSignUp: PropTypes.func.isRequired,
  onHandleLogIn: PropTypes.func.isRequired
};

export default NotLoggedDesktopButton;
