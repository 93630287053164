import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';

import { useCitiesContext } from '@spotahome/ui-library';
import FAQSection from '@spotahome/ui-library/src/tenant/components/FAQSection';

import PropertyTypeSection from '@spotahome/landings-common/src/components/PropertyTypeSection';
import CitiesShowcase from '@spotahome/landings-common/src/components/CitiesShowcase';
import Footer from '@spotahome/landings-common/src/components/Footer';
import OptInWrapper from '@spotahome/landings-common/src/components/OptInWrapper';

import { getTenantFaq } from '@spotahome/landings-common/src/utils/faq';

import HomeFooter from './HomeFooter';
import CardTestimonialQuoteSection from './CardTestimonialQuoteSection';
import GuaranteeSection from './GuaranteeSection';
import Hero from './Hero';
import FeatureBoxSection from './FeatureBoxSection';
import QuoteSection from './QuoteSection';

const Content = ({ deviceType, isPartner }) => {
  const { cities } = useCitiesContext();

  return (
    <div>
      <Hero cityList={cities} deviceType={deviceType} />
      <FeatureBoxSection />
      <QuoteSection />
      {!isPartner ? (
        <>
          <GuaranteeSection />
          <PropertyTypeSection deviceType={deviceType} />
          <CitiesShowcase cityList={cities} deviceType={deviceType} />
          <NoSSR>
            <CardTestimonialQuoteSection />
            <FAQSection questions={getTenantFaq()} />
            <HomeFooter />
            <Footer />
            <OptInWrapper />
          </NoSSR>
        </>
      ) : null}
    </div>
  );
};

Content.propTypes = {
  deviceType: PropTypes.string.isRequired,
  isPartner: PropTypes.bool.isRequired
};

export default Content;
