import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import MobileMenuItem from '../MobileMenu/MobileMenuItem';
import MobileMenuLink from '../MobileMenu/MobileMenuLink';

const MobileLink = ({ dataTest = '', ...props }) => (
  <MobileMenuItem>
    <MobileMenuLink {...props} data-test={dataTest}>
      {trans(props.trans)}
    </MobileMenuLink>
  </MobileMenuItem>
);

MobileLink.propTypes = {
  trans: PropTypes.string.isRequired,
  dataTest: PropTypes.string
};

export default MobileLink;
