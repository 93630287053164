/* global ga */
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import { getLoginImagePath } from '../utils/getExternalImagePath';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';
import FooterStoreIcon from './components/FooterStoreIcon';

const track = ({ eventCategory, eventAction, eventLabel }) => {
  if (typeof ga === 'function') {
    ga('send', {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel
    });
  }
};

const AppFooter = ({ android, ios, linkTheme }) => (
  <FooterBlock>
    <FooterBlockTitle text={trans('footer.label.get_app')} />
    <FooterBlockList isInline>
      <FooterLink
        linkTheme={linkTheme}
        href={android.link}
        onClick={() => track(android)}
      >
        <FooterStoreIcon
          src={getLoginImagePath('google-play.png')}
          alt="Google Play Store"
        />
      </FooterLink>
      <FooterLink
        linkTheme={linkTheme}
        href={ios.link}
        onClick={() => track(ios)}
      >
        <FooterStoreIcon
          src={getLoginImagePath('app-store.png')}
          alt="App Store"
        />
      </FooterLink>
    </FooterBlockList>
  </FooterBlock>
);

AppFooter.propTypes = {
  android: PropTypes.shape({
    link: PropTypes.string,
    eventCategory: PropTypes.string,
    eventAction: PropTypes.string,
    eventLabel: PropTypes.string
  }),
  ios: PropTypes.shape({
    link: PropTypes.string,
    eventCategory: PropTypes.string,
    eventAction: PropTypes.string,
    eventLabel: PropTypes.string
  }),
  linkTheme: PropTypes.string.isRequired
};

AppFooter.defaultProps = {
  android: {
    link: 'https://spotahome.app.link/footer-icon-android',
    eventCategory: 'app-promotion',
    eventAction: 'footer-icon-android',
    eventLabel: 'open'
  },
  ios: {
    link: 'https://spotahome.app.link/footer-icon-ios',
    eventCategory: 'app-promotion',
    eventAction: 'footer-icon-ios',
    eventLabel: 'open'
  }
};

export default AppFooter;
