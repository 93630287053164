export const testimonialQuotes = [
  {
    message: 'home.tenant.testimonial-quote.first-item.quote',
    source: 'home.tenant.testimonial-quote.first-item.quote-source',
    rating: {
      ratingValue: '4.8',
      bestRating: '5',
      worstRating: '0'
    }
  },
  {
    message: 'home.tenant.testimonial-quote.second-item.quote',
    source: 'home.tenant.testimonial-quote.second-item.quote-source',
    rating: {
      ratingValue: '4.7',
      bestRating: '5',
      worstRating: '0'
    }
  },
  {
    message: 'home.tenant.testimonial-quote.third-item.quote',
    source: 'home.tenant.testimonial-quote.third-item.quote-source',
    rating: {
      ratingValue: '4.8',
      bestRating: '5',
      worstRating: '0'
    }
  }
];
