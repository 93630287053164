import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import Dropdown from '../Dropdown';
import DropdownItem from '../DropdownItem';

const LoggedInDesktopButton = ({
  userName,
  typeMenu,
  myProfilePath,
  myReferralsPath,
  myPaymentsPath,
  userAreaPath,
  searchPreferencePath,
  logoutPath
}) => (
  <Dropdown
    title={userName}
    position="right"
    color="light"
    textTransform="capitalize"
    className={typeMenu}
    arrowIcon
    dataTest="logged-in-menu"
  >
    <DropdownItem className="ga-my-profile">
      <a href={myProfilePath}>{trans('logout.text.my-profile')}</a>
    </DropdownItem>

    <DropdownItem className="ga-search-preference">
      <a href={searchPreferencePath}>
        {trans('logout.text.search-preference')}
      </a>
    </DropdownItem>

    <DropdownItem className="ga-user-area">
      <a href={userAreaPath}>{trans('logout.text.user-area')}</a>
    </DropdownItem>

    <DropdownItem className="ga-my-referrals">
      <a href={myReferralsPath}>{trans('header.nav.my-referrals.link')}</a>
    </DropdownItem>

    <DropdownItem className="ga-my-payments">
      <a href={myPaymentsPath}>{trans('header.nav.my-payments.link')}</a>
    </DropdownItem>

    <DropdownItem>
      <a href={logoutPath}>{trans('logout.text')}</a>
    </DropdownItem>
  </Dropdown>
);

LoggedInDesktopButton.propTypes = {
  typeMenu: PropTypes.oneOf(['menu-white', 'menu-transparent']).isRequired,
  userName: PropTypes.string.isRequired,
  myProfilePath: PropTypes.string.isRequired,
  myReferralsPath: PropTypes.string.isRequired,
  myPaymentsPath: PropTypes.string.isRequired,
  userAreaPath: PropTypes.string.isRequired,
  searchPreferencePath: PropTypes.string.isRequired,
  logoutPath: PropTypes.string.isRequired
};

export default LoggedInDesktopButton;
