import { useState, useEffect } from 'react';
import { useSoyuzLocales } from '@spotahome/soyuz/client';

import './SafetySpinner.scss';

const ICON_BASE_PATH =
  'https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home';

const SafetySpinner = () => {
  const [scrollY, setScrollY] = useState(0);
  const { current } = useSoyuzLocales();

  const iconPath = `${ICON_BASE_PATH}/safety-icon-${current}.svg`;

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY / 3);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="safety-spinner">
      <svg
        style={{
          transform: `rotate(${scrollY}deg)`
        }}
      >
        <image xlinkHref={iconPath} />
      </svg>
    </div>
  );
};

export default SafetySpinner;
