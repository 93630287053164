import { trans } from '@spotahome/soyuz-trans/client';

import styles from './Footer.module.scss';

const getYear = () => new Date().getFullYear();

const CopyrightFooter = () => (
  <span className={styles['footer__disclaimer-copyright']}>
    ©{getYear()} Spotahome — {trans('footer.label.all_rights_reserved')}
  </span>
);

export default CopyrightFooter;
