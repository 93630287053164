import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import { useThemeFlag } from '../../../base/ThemeProvider';

import Button from '../Button';

import styles from './Footer.module.scss';

const HelpCenterButton = ({ color = 'light', href = '#', ...props }) => {
  const newButtonsEnabled = useThemeFlag('newButtons');
  return (
    <Button
      className={styles['footer__help-center']}
      fullWidth={!newButtonsEnabled}
      alt
      size={newButtonsEnabled ? 'medium' : 'big'}
      color={color}
      href={href}
      {...props}
    >
      {trans('footer.item.help_center')}
    </Button>
  );
};

HelpCenterButton.propTypes = {
  href: PropTypes.string,
  color: PropTypes.string
};

export default HelpCenterButton;
