const Clock = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="26" fill="#FFCCEF" />
    <path d="M56 30C56 15.6406 44.3594 4 30 4V30H56Z" fill="#FB6E44" />
  </svg>
);

export default Clock;
