import { trans } from '@spotahome/soyuz-trans/client';

import { getFooterImagePath } from '../utils/getExternalImagePath';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterPaymentMethod from './components/FooterPaymentMethod';

const PaymentFooter = () => (
  <FooterBlock>
    <FooterBlockTitle text={trans('footer.item.payment_methods')} />
    <FooterPaymentMethod src={getFooterImagePath('visa.png')} alt="Visa" />
    <FooterPaymentMethod
      src={getFooterImagePath('mastercard.png')}
      alt="Mastercard"
    />
    <FooterPaymentMethod src={getFooterImagePath('paypal.png')} alt="PayPal" />
  </FooterBlock>
);

export default PaymentFooter;
