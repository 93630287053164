import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const SpotahomeFooter = props => {
  const spotahomeLinks = [
    {
      url: props.aboutUs,
      locoKey: 'footer.item.about_us'
    },
    {
      url: props.collaborators,
      locoKey: 'footer.item.collaborators'
    },
    {
      url: props.becomePartner,
      locoKey: 'footer.item.become_partner'
    },
    {
      url: props.institutions,
      locoKey: 'footer.item.institutions'
    },
    {
      url: props.workWithUs,
      locoKey: 'footer.item.work_with_us'
    },
    {
      url: props.internships,
      locoKey: 'footer.item.internships'
    },
    {
      url: props.studentAmbassador,
      locoKey: 'footer.item.studentAmbassador'
    },
    {
      url: props.termsAndConditions,
      locoKey: 'footer.item.terms_and_conditions'
    },
    {
      url: props.companyDetails,
      locoKey: 'footer.item.company_details'
    },
    {
      url: props.cookiesPolicy,
      locoKey: 'footer.item.cookies_policy'
    },
    {
      url: props.privacyPolicies,
      locoKey: 'footer.item.privacy_policies'
    },
    {
      url: props.sitemap,
      locoKey: 'footer.item.sitemap'
    }
  ];
  const NO_FOLLOW_PAGES = [
    props.privacyPolicies,
    props.cookiesPolicy,
    props.companyDetails
  ];

  return (
    <FooterBlock>
      <FooterBlockTitle text="Spotahome" />
      <FooterBlockList>
        {spotahomeLinks.map(({ locoKey, url }) => (
          <FooterLink
            key={locoKey}
            linkTheme={props.linkTheme}
            href={url}
            rel={NO_FOLLOW_PAGES.includes(url) ? 'nofollow' : ''}
          >
            {trans(locoKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </FooterBlock>
  );
};

SpotahomeFooter.propTypes = {
  aboutUs: PropTypes.string.isRequired,
  cookiesPolicy: PropTypes.string.isRequired,
  institutions: PropTypes.string.isRequired,
  internships: PropTypes.string.isRequired,
  collaborators: PropTypes.string.isRequired,
  becomePartner: PropTypes.string.isRequired,
  privacyPolicies: PropTypes.string.isRequired,
  sitemap: PropTypes.string.isRequired,
  studentAmbassador: PropTypes.string.isRequired,
  termsAndConditions: PropTypes.string.isRequired,
  workWithUs: PropTypes.string.isRequired,
  linkTheme: PropTypes.string.isRequired
};

export default SpotahomeFooter;
