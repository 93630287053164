import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tracking from '@spotahome/soyuz-tracking';
import { ToastContainer, toast, Bounce } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import CloseButton from '../CloseButton/CloseButton';

import toastStyles from './Toast.module.scss';

const RebrandingCloseButton = ({ closeToast }) => (
  <CloseButton
    color="black"
    onClick={e => {
      e.stopPropagation();
      closeToast(e);
    }}
    className={toastStyles['toast-layout__close-button']}
  />
);

RebrandingCloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired
};

export const ToastLayout = ({
  renderContent,
  renderIcon,
  renderTitle,
  theme,
  compact
}) => {
  const toastClass = classNames(toastStyles['toast-layout'], {
    [toastStyles[`toast-layout--${theme}`]]: theme,
    [toastStyles['toast-layout--compact']]: compact
  });

  return (
    <div className={toastClass}>
      {renderIcon()}
      <div className={toastStyles['toast-layout__content-wrapper']}>
        {renderTitle && (
          <div className={toastStyles['toast-layout__title']}>
            {renderTitle()}
          </div>
        )}
        <div className={toastStyles['toast-layout__content']}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

ToastLayout.propTypes = {
  renderContent: PropTypes.func.isRequired,
  renderIcon: PropTypes.func,
  renderTitle: PropTypes.func,
  theme: PropTypes.oneOf([
    '',
    'default',
    'warning',
    'info',
    'rebranding',
    'offer'
  ]),
  compact: PropTypes.bool
};

ToastLayout.defaultProps = {
  renderIcon: () => null,
  renderTitle: null,
  compact: false,
  theme: ''
};

const Container = () => (
  <ToastContainer
    hideProgressBar
    position="top-right"
    transition={Bounce}
    toastClassName={toastStyles['toast-container']}
  />
);

const trackEvent = eventProps => {
  if (eventProps) {
    Tracking.ga.sendEvent(
      eventProps.category,
      eventProps.action,
      eventProps.label
    );
  }
};

const displayToast = (toastProps, toastOptions, theme = 'default') => {
  toast(<ToastLayout {...toastProps} />, {
    ...toastOptions,
    onClick: toastOptions.onClick ? toastOptions.onClick : null,
    onOpen: props => {
      trackEvent(toastOptions.openEvent);

      if (toastOptions.onOpen) {
        toastOptions.onOpen(props);
      }
    },
    onClose: props => {
      trackEvent(toastOptions.closeEvent);
      if (toastOptions.onClose) {
        toastOptions.onClose(props);
      }
    },
    closeButton: <RebrandingCloseButton />,
    className: [toastOptions.className, toastStyles[theme]]
      .filter(Boolean)
      .join(' '),
    draggable: false
  });
};

export const showToast = (toastProps, toastOptions = {}) => {
  displayToast(toastProps, toastOptions, 'default');
};

export const showWarningToast = (toastProps, toastOptions = {}) => {
  displayToast(toastProps, toastOptions, 'warning');
};

export const showRebrandingToast = (toastProps, toastOptions) => {
  displayToast(toastProps, toastOptions, 'rebranding');
};

export const showInformationToast = (toastProps, toastOptions = {}) => {
  displayToast(toastProps, toastOptions, 'info');
};

export const showOfferToast = (toastProps, toastOptions = {}) => {
  displayToast(toastProps, toastOptions, 'offer');
};

export const dismissAllToast = () => {
  toast.dismiss();
};

export default Container;
