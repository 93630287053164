import { trans } from '@spotahome/soyuz/client';

import Lock from '../icons/Lock';
import Clock from '../icons/Clock';
import Eye from '../icons/Eye';

import './Guarantees.scss';

const GUARANTEES = [
  {
    title: 'how_it_works.guarantee.block1.title',
    text: 'how_it_works.guarantee.block1.text',
    renderIcon: () => <Lock />
  },
  {
    title: 'how_it_works.guarantee.block2.title',
    text: 'how_it_works.guarantee.block2.text',
    renderIcon: () => <Clock />
  },
  {
    title: 'how_it_works.guarantee.block3.title',
    text: 'how_it_works.guarantee.block3.text',
    renderIcon: () => <Eye />
  }
];

const Guarantees = () => (
  <div id="guarantee" className="guarantees">
    <span className="guarantees__title">
      {trans('how_it_works.guarantee.title2')}
    </span>

    <div className="guarantees__block-wrapper">
      {GUARANTEES.map(({ title, text, renderIcon }) => (
        <div className="guarantees__block">
          <div>
            <div>{renderIcon()}</div>
            <h5 className="guarantees__block-title">{trans(title)}</h5>
            <p className="guarantees__text">{trans(text)}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Guarantees;
