const Instagram = ({ className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.64746 0C2.0825 0.00400462 0.00418773 2.08232 0.000183105 4.64727V11.3527C0.00418773 13.9177 2.0825 15.996 4.64746 16H11.3529C13.9179 15.996 15.9962 13.9177 16.0002 11.3527V4.64727C15.9962 2.08232 13.9179 0.00400462 11.3529 0H4.64746ZM4.64746 1.22906H11.3529C12.2607 1.2252 13.1323 1.58408 13.7742 2.22596C14.4161 2.86784 14.775 3.73953 14.7711 4.64727V11.3527C14.775 12.2605 14.4161 13.1322 13.7742 13.774C13.1323 14.4159 12.2607 14.7748 11.3529 14.7709H4.64746C3.73971 14.7748 2.86802 14.4159 2.22614 13.774C1.58427 13.1322 1.22539 12.2605 1.22924 11.3527V4.64727C1.22539 3.73953 1.58427 2.86784 2.22614 2.22596C2.86802 1.58408 3.73971 1.2252 4.64746 1.22906ZM12.6475 2.45818C12.1373 2.45818 11.7238 2.87171 11.7238 3.38182C11.7238 3.89193 12.1373 4.30545 12.6475 4.30545C13.1576 4.30545 13.5711 3.89193 13.5711 3.38182C13.5712 3.13173 13.4699 2.89228 13.2904 2.7182C13.1108 2.54413 12.8683 2.4503 12.6184 2.45818H12.6475ZM8.00018 3.69455C5.62235 3.69455 3.69473 5.62216 3.69473 8C3.69473 10.3778 5.62235 12.3055 8.00018 12.3055C10.378 12.3055 12.3056 10.3778 12.3056 8C12.3016 5.62382 10.3764 3.69855 8.00018 3.69455ZM8.00018 4.9236C9.69921 4.9236 11.0765 6.30097 11.0765 8C11.0765 9.69903 9.69921 11.0764 8.00018 11.0764C6.30115 11.0764 4.92378 9.69903 4.92378 8C4.91993 7.18291 5.24279 6.39816 5.82057 5.82039C6.39835 5.24261 7.18309 4.91975 8.00018 4.9236Z"
      fill="#0D0D0D"
    />
  </svg>
);

export default Instagram;
