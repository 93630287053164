import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const sizes = {
  small: {
    width: 6,
    height: 12
  },
  normal: {
    width: 8,
    height: 16
  },
  big: {
    width: 10,
    height: 20
  }
};

const THEMES = {
  light: Colors.white,
  dark: Colors.dark
};

const FacebookIcon = ({ className = '', size = 'normal', theme = 'light' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={sizes[size].width}
    height={sizes[size].height}
    viewBox="0 0 11 22"
    className={className}
    aria-label="facebook-icon"
  >
    <path
      fill={THEMES[theme]}
      d="M10.23 11H7.15v11H2.53V11H.33V7.15h2.2V4.62C2.53 2.86 3.41 0 7.15 0h3.41v3.74H8.14c-.44 0-.99.22-.99 1.1v2.31h3.52L10.23 11z"
    />
  </svg>
);

FacebookIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default FacebookIcon;
