import PropTypes from 'prop-types';

const MobileIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="44"
    fill="none"
    viewBox="0 0 27 44"
    className={props.className}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M23.982 3.67v1.16h-21.5V3.67c0-.65.536-1.19 1.195-1.19h19.106a1.2 1.2 0 0 1 1.2 1.19zm-21.5 28.61h21.5V7.31h-21.5v24.97zm21.5 2.48v5.56c0 .66-.538 1.2-1.199 1.2H3.677a1.2 1.2 0 0 1-1.195-1.2v-5.56h21.5zM22.783 0H3.677A3.68 3.68 0 0 0 0 3.67v36.65C0 42.35 1.649 44 3.677 44h19.106a3.682 3.682 0 0 0 3.677-3.68V3.67A3.68 3.68 0 0 0 22.783 0z"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M15.236 38.14c0-1.11-.9-2.01-2.006-2.01-1.108 0-2.006.9-2.006 2.01 0 1.11.898 2.01 2.006 2.01 1.107 0 2.006-.9 2.006-2.01z"
      clipRule="evenodd"
    />
  </svg>
);

export default MobileIcon;

MobileIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

MobileIcon.defaultProps = {
  className: '',
  color: '#32B18F'
};
