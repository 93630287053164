import PropTypes from 'prop-types';
import { Colors } from '@spotahome/ui-library';

const themes = {
  oldTheme: '#35c2a9',
  purpleLight: '#880068',
  newTheme: Colors.secondary
};

const HomeCheckIcon = ({ theme, ...props }) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.618 41.35H2.64905V17.57L22 2.98L41.351 17.57V41.35H27.618ZM43.473 15.85L22.798 0.26C22.563 0.09 22.281 0 22 0C21.719 0 21.437 0.09 21.203 0.26L0.528 15.85C0.197 16.1 0 16.5 0 16.91V42.68C0 43.41 0.595 44 1.325 44H42.676C43.406 44 44 43.41 44 42.68V16.91C44 16.5 43.803 16.1 43.473 15.85Z"
      fill={themes[theme]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.2212 19.27L19.8422 29.72L14.6302 24.76L12.7422 26.74L19.9952 33.65L31.2582 21.1L29.2212 19.27Z"
      fill={themes[theme]}
    />
  </svg>
);

HomeCheckIcon.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

HomeCheckIcon.defaultProps = {
  theme: 'purpleLight'
};

export default HomeCheckIcon;
