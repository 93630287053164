import { getResourcePath, useSoyuzLocales } from '@spotahome/soyuz/client';

import { Footer as FooterComponent } from '@spotahome/ui-library';
import { useLocaleChange } from '@spotahome/ui-library/src/hooks/useLocaleChange';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

function handleHelpClick(e) {
  e.preventDefault();
  if (typeof zE !== 'undefined') {
    zE.activate({ hideOnClose: true });
  }
}

const Footer = () => {
  const handleOnLocaleChange = useLocaleChange();
  const { current } = useSoyuzLocales();

  return (
    <FooterComponent
      theme="beige"
      help={{
        onClick: handleHelpClick
      }}
      locales={{ current, onChange: handleOnLocaleChange }}
      apps={{
        android: {
          link: 'https://spotahome.app.link/android_footer_home',
          eventCategory: 'app-promotion',
          eventAction: 'footer-icon-android',
          eventLabel: 'open'
        },
        ios: {
          link: 'https://spotahome.app.link/ios_footer_home',
          eventCategory: 'app-promotion',
          eventAction: 'footer-icon-ios',
          eventLabel: 'open'
        }
      }}
      icons={{
        prs: {
          icon: getResourcePath('/images/footer_image/prs.svg')
        },
        cmp: {
          icon: getResourcePath('/images/footer_image/cmp.png')
        }
      }}
    />
  );
};

export default withInViewEvent(LANDING_SECTIONS.footer)(Footer);
