import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import { useHeaderLinks } from '../../../context/ExternalLink';

import { ACTIONS } from '../constants';

import styles from './LoginSignupModalTermsAndConditions.module.scss';

const LoginSignupModalTermsAndConditions = ({ action }) => {
  const { termsAndConditions, privacyPolicies } = useHeaderLinks();

  return (
    <div
      // eslint-disable-next-line react/no-danger
      className={styles['login-signup-modal-terms-and-conditions']}
      dangerouslySetInnerHTML={{
        __html: trans(`authentication.${action}.terms_and_conditions`, {
          terms: termsAndConditions,
          privacy: privacyPolicies
        })
      }}
    />
  );
};

LoginSignupModalTermsAndConditions.propTypes = {
  action: PropTypes.oneOf(ACTIONS).isRequired
};

export default LoginSignupModalTermsAndConditions;
