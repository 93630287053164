import PropTypes from 'prop-types';

const TwitterIcon = ({ className = '', fill = 'black' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="22"
    viewBox="0 0 28 22"
    className={className}
  >
    <path
      fill={fill}
      d="M24.554 5.425v.76c0 7.27-5.492 15.624-15.508 15.624-3.123 0-5.923-.868-8.292-2.495.43 0 .861.108 1.292.108 2.585 0 4.846-.868 6.785-2.387-2.37 0-4.416-1.628-5.062-3.798.323.109.646.109.97.109.538 0 .969-.109 1.4-.217-2.477-.543-4.308-2.713-4.308-5.317v-.108a4.973 4.973 0 0 0 2.477.65c-1.508-.976-2.37-2.712-2.37-4.556 0-.977.324-1.953.754-2.713 2.693 3.364 6.677 5.534 11.2 5.75-.107-.433-.107-.867-.107-1.301 0-3.038 2.477-5.425 5.384-5.425 1.616 0 3.016.65 3.985 1.736 1.292-.217 2.37-.651 3.446-1.302-.43 1.302-1.292 2.387-2.37 3.038 1.078-.109 2.155-.434 3.124-.868-.862.976-1.723 1.953-2.8 2.712z"
    />
  </svg>
);

TwitterIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string
};

export default TwitterIcon;
