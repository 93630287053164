import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import { useBaseLinks } from '../../context/ExternalLink';

import LanguageSwitcher from '../LanguageSwitcher';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

import HelpCenterButton from './HelpCenterButton';

import styles from './Footer.module.scss';

const HelpFooter = props => {
  const localeLinks = useBaseLinks();
  const { help, locales, linkTheme, showContactInfo } = props;
  const handleLocaleChange = locales.onChange;

  return (
    <FooterBlock>
      <FooterBlockTitle text={trans('footer.label.help')} />
      {showContactInfo && (
        <FooterBlockList>
          <li>{trans('footer.item.customer_support')}</li>
          <FooterLink
            linkTheme={linkTheme}
            href={`tel:${trans('footer.help.telephone')}`}
          >
            {trans('footer.help.telephone')}
          </FooterLink>
          <FooterLink
            linkTheme={linkTheme}
            href={`mailto:${trans('footer.help.email')}`}
          >
            {trans('footer.help.email')}
          </FooterLink>
        </FooterBlockList>
      )}
      <HelpCenterButton color="primary" href={help} />
      <div className={styles['footer__language-switcher']}>
        <span className={styles['footer__language-switcher-label']}>
          {trans('footer.label.language')}{' '}
        </span>
        <LanguageSwitcher
          onChange={handleLocaleChange}
          locales={locales.list || Object.keys(localeLinks)}
          current={locales.current}
          theme="dark"
        />
      </div>
    </FooterBlock>
  );
};

HelpFooter.propTypes = {
  help: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    current: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  linkTheme: PropTypes.string.isRequired,
  showContactInfo: PropTypes.bool.isRequired
};

export default HelpFooter;
