import PropTypes from 'prop-types';

import LazyLoad from 'react-lazyload';
import classNames from 'classnames';

import { getFooterImagePath } from '../utils/getExternalImagePath';

import Link from '../Link';

import IconPRS from '../icons/IconPRS';

import Colors from '../utils/colors';

import FooterBlock from './components/FooterBlock';

import styles from './Footer.module.scss';

const TrustIconsFooter = ({ prs, cmp, className, linkTheme, ...rest }) => {
  const isRebranding = true;

  return (
    <FooterBlock
      {...rest}
      className={classNames(styles['footer__trust-icons-container'], className)}
    >
      <Link
        theme={linkTheme}
        href="https://www.theprs.co.uk/"
        target="_blank"
        rel="noopener noreferrer"
        {...prs}
        className={classNames(styles['footer__trust-icon'], prs.className)}
      >
        <IconPRS color={isRebranding ? Colors.shadow : Colors.primary} />
      </Link>
    </FooterBlock>
  );
};

TrustIconsFooter.propTypes = {
  className: PropTypes.string,
  prs: PropTypes.shape({
    className: PropTypes.string,
    href: PropTypes.string
  }),
  cmp: PropTypes.shape({
    icon: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string
  }),
  linkTheme: PropTypes.string.isRequired
};
TrustIconsFooter.defaultProps = {
  className: '',
  prs: {},
  cmp: {}
};
export default TrustIconsFooter;
