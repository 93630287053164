import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  trans,
  useSoyuzLocales,
  useSoyuzExperiment
} from '@spotahome/soyuz/client';
import {
  InfoBanner,
  MediaQuery,
  TrustpilotWidget
} from '@spotahome/ui-library';
import { DSIcon } from '@spotahome/ui-library/base';
import HistoricalMetricsRedesign from '@spotahome/ui-library/src/tenant/components/HistoricalMetricsRedesign';

import classNames from 'classnames';

import HeroContainer from '@spotahome/landings-common/src/components/HeroContainer';
import HeaderContainer from '@spotahome/landings-common/src/components/HeaderContainer';
import Tracking from '@spotahome/soyuz-tracking';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import CityAutocompleter from './CityAutocompleter';

import './HeroRedesign.scss';

const { isMobileScreen, isPhabletScreen, isTabletScreen } = MediaQuery;

const HeroTenantRedesign = ({ cityList, deviceType }) => {
  const [isDark, setIsDark] = useState(true);
  useEffect(() => {
    setIsDark(isMobileScreen() || isPhabletScreen() || isTabletScreen());
  }, []);
  const { current: locale } = useSoyuzLocales();

  const handleTrustpilotClick = () => {
    Tracking.ga.sendEvent('Home', 'trustpilot-widget', 'click');
  };

  const isHomepageMonumentsImage = deviceType === 'desktop';

  const heroTenantWrapperClasses = classNames('hero-tenant-redesign__wrapper', {
    'hero-tenant-redesign__wrapper--background-image': isHomepageMonumentsImage
  });

  const isBestPriceExp =
    useSoyuzExperiment('BestPriceGuaranteed').get('behaviour') === 'variant';

  const containerClasses = classNames('hero-tenant-redesign__container', {
    'hero-tenant-redesign__container-higher': isBestPriceExp
  });

  return (
    <section>
      <HeroContainer>
        <div className="hero-tenant-redesign__header-wrapper">
          <HeaderContainer
            color={{
              foregroundColor: isDark ? 'dark' : 'light',
              logoColor: isDark ? 'dark' : 'light'
            }}
            tracking="ga-home-tenant"
          />
        </div>
        <div className={heroTenantWrapperClasses}>
          <div className={containerClasses}>
            <div className="hero-tenant-redesign__info">
              <div className="hero-tenant-redesign__info-content">
                <h1 className="hero-tenant-redesign__info-title">
                  {trans('home.tenant.hero.title.experiment')}
                </h1>
                <p className="hero-tenant-redesign__info-subtitle">
                  {trans('home.tenant.hero.subtitle.experiment')}
                </p>
                {isBestPriceExp && (
                  <InfoBanner
                    className="hero-tenant-redesign__best-price"
                    theme="suggestion-light"
                    closable={false}
                    icon={<DSIcon name="credit-card" />}
                    content={
                      <span>{trans('best.price.listing.banner.title')}</span>
                    }
                  />
                )}
                <CityAutocompleter
                  cities={cityList}
                  deviceType={deviceType}
                  className="hero-tenant-redesign__city-selector"
                />
              </div>
            </div>
            <div className="hero-tenant-redesign__container-bottom hero-tenant-redesign__container-bottom--centered">
              {deviceType === 'desktop' && (
                <img
                  className="hero-tenant-redesign__monument"
                  src="https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home-monuments-redesign-desktop.svg"
                  alt="Momument"
                  rel="preload"
                  width="500"
                />
              )}
              <div className="hero-tenant-redesign__trustpilot-wrapper">
                <TrustpilotWidget
                  locale={locale}
                  onClick={handleTrustpilotClick}
                />
              </div>
            </div>
          </div>
        </div>
      </HeroContainer>
      <HistoricalMetricsRedesign />
    </section>
  );
};

HeroTenantRedesign.propTypes = {
  cityList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deviceType: PropTypes.oneOf(['smartphone', 'tablet', 'desktop']).isRequired
};

export default withInViewEvent(LANDING_SECTIONS.hero)(HeroTenantRedesign);
