import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './TextBlock.scss';

const TextBlock = ({
  title,
  content,
  alignTo,
  titleSize,
  titleTag: Tag,
  contentSize,
  className,
  style
}) => {
  const containerClass = classNames('text-block__container', className, {
    'text-block--align-right': alignTo === 'right',
    'text-block--align-left': alignTo === 'left',
    'text-block--align-center': alignTo === 'center'
  });
  const titleClass = classNames('text-block__title', {
    'text-block__title--big': titleSize === 'big',
    'text-block__title--medium': titleSize === 'medium'
  });
  const contentClass = classNames('text-block__content', {
    'text-block__content--big': contentSize === 'big',
    'text-block__content--medium': contentSize === 'medium'
  });

  return (
    <div className={containerClass} style={style}>
      <Tag className={titleClass}>{title}</Tag>
      <p
        className={contentClass}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  alignTo: PropTypes.oneOf(['right', 'left', 'center']).isRequired,
  titleSize: PropTypes.oneOf(['big', 'medium']).isRequired,
  titleTag: PropTypes.string,
  contentSize: PropTypes.oneOf(['big', 'medium']).isRequired,
  className: PropTypes.string,
  style: PropTypes.string
};

TextBlock.defaultProps = {
  titleTag: 'h3',
  className: '',
  style: {}
};
export default TextBlock;
