export default {
  landing: {
    en: '/',
    es: '/es',
    it: '/it',
    fr: '/fr',
    de: '/de',
    tr: '/tr',
    pl: '/pl',
    pt: '/pt'
  },
  publishYourProperty: {
    en: '/landlords',
    es: '/es/propietarios',
    it: '/it/proprietari',
    fr: '/fr/proprietaires',
    de: '/de/fuer-den-vermieter',
    tr: '/tr/ev-sahipleri',
    pl: '/pl/wlasciciele',
    pt: '/pt/publique-a-sua-propriedade'
  },
  howItWorks: {
    en: '/how-it-works',
    es: '/es/como-funciona',
    it: '/it/come-funziona',
    fr: '/fr/comment-fonctionne',
    de: '/de/wie-es-funktioniert',
    tr: '/tr/nasil-calisiyor',
    pl: '/pl/jak-to-dziala',
    pt: '/pt/como-funciona'
  },
  favorites: {
    en: '/favorites',
    es: '/es/favorites',
    it: '/it/favorites',
    fr: '/fr/favorites',
    de: '/de/favorites',
    tr: '/tr/favorites',
    pl: '/pl/favorites',
    pt: '/pt/favorites'
  },
  help: {
    de: 'https://spotahome.zohodesk.com/portal/de/kb/about-spotahome/tenant',
    es: 'https://spotahome.zohodesk.com/portal/es/kb/about-spotahome/tenant',
    fr: 'https://spotahome.zohodesk.com/portal/fr/kb/about-spotahome/tenant',
    it: 'https://spotahome.zohodesk.com/portal/it/kb/about-spotahome/tenant',
    en: 'https://spotahome.zohodesk.com/portal/en/kb/help-center/tenant',
    tr: 'https://spotahome.zohodesk.com/portal/en/kb/help-center/tenant',
    pl: 'https://spotahome.zohodesk.com/portal/en/kb/help-center/tenant',
    pt: 'https://spotahome.zohodesk.com/portal/pt/kb/about-spotahome/tenant'
  },
  myRequests: {
    en: '/my-requests',
    es: '/es/my-requests',
    it: '/it/my-requests',
    fr: '/fr/my-requests',
    de: '/de/my-requests',
    tr: '/tr/my-requests',
    pl: '/pl/my-requests',
    pt: '/pt/my-requests'
  },
  myProfile: {
    en: '/my-profile',
    es: '/es/my-profile',
    it: '/it/my-profile',
    fr: '/fr/my-profile',
    de: '/de/my-profile',
    tr: '/tr/my-profile',
    pl: '/pl/my-profile',
    pt: '/pt/my-profile'
  },
  myReferrals: {
    en: '/my-referrals',
    es: '/es/my-referrals',
    it: '/it/my-referrals',
    fr: '/fr/my-referrals',
    de: '/de/my-referrals',
    tr: '/tr/my-referrals',
    pl: '/pl/my-referrals',
    pt: '/pt/my-referrals'
  },
  myPayments: {
    en: '/my-payments',
    es: '/es/my-payments',
    it: '/it/my-payments',
    fr: '/fr/my-payments',
    de: '/de/my-payments',
    tr: '/tr/my-payments',
    pl: '/pl/my-payments',
    pt: '/pt/my-payments'
  },
  support: {
    en: '/support',
    es: '/es/support',
    it: '/it/support',
    fr: '/fr/support',
    de: '/de/support',
    tr: '/tr/support',
    pl: '/pl/support',
    pt: '/pt/support'
  },
  searchPreferences: {
    en: '/my-alerts',
    es: '/es/my-alerts',
    it: '/it/my-alerts',
    fr: '/fr/my-alerts',
    de: '/de/my-alerts',
    tr: '/tr/my-alerts',
    pl: '/pl/my-alerts',
    pt: '/pt/my-alerts'
  },
  logout: {
    de: '/marketplace/logout',
    es: '/marketplace/logout',
    fr: '/marketplace/logout',
    it: '/marketplace/logout',
    en: '/marketplace/logout',
    tr: '/marketplace/logout',
    pl: '/marketplace/logout',
    pt: '/marketplace/logout'
  },
  termsAndConditions: {
    en: '/terms-and-conditions',
    es: '/es/terminos-y-condiciones',
    it: '/it/termini-e-condizioni',
    fr: '/fr/termes-et-conditions',
    de: '/de/geschaftsbedingungen',
    tr: '/tr/sartlar-ve-kosullar',
    pl: '/terms-and-conditions',
    pt: '/terms-and-conditions'
  },
  landlordPanel: {
    de: 'https://landlord.spotahome.com/de/login/',
    es: 'https://landlord.spotahome.com/es/login/',
    fr: 'https://landlord.spotahome.com/fr/login/',
    it: 'https://landlord.spotahome.com/it/login/',
    en: 'https://landlord.spotahome.com/login/',
    tr: 'https://landlord.spotahome.com/login/',
    pl: 'https://landlord.spotahome.com/login/',
    pt: 'https://landlord.spotahome.com/pt/login/'
  },
  landlordSignup: {
    de: 'https://landlord.spotahome.com/de/signup/',
    es: 'https://landlord.spotahome.com/es/signup/',
    fr: 'https://landlord.spotahome.com/fr/signup/',
    it: 'https://landlord.spotahome.com/it/signup/',
    en: 'https://landlord.spotahome.com/signup/',
    tr: 'https://landlord.spotahome.com/signup/',
    pl: 'https://landlord.spotahome.com/signup/',
    pt: 'https://landlord.spotahome.com/pt/signup/'
  },
  privacyPolicies: {
    en: '/privacy-policies',
    es: '/es/politica-privacidad',
    it: '/it/privacy-policies',
    fr: '/fr/privacy-policies',
    de: '/de/privacy-policies',
    tr: '/tr/privacy-policies',
    pl: '/pl/privacy-policies',
    pt: '/pt/privacy-policies'
  },
  googleLogin: {
    en: '/marketplace/login/google?locale=en&subscribe=no',
    es: '/marketplace/login/google?locale=es&subscribe=no',
    it: '/marketplace/login/google?locale=it&subscribe=no',
    fr: '/marketplace/login/google?locale=fr&subscribe=no',
    de: '/marketplace/login/google?locale=de&subscribe=no',
    tr: '/marketplace/login/google?locale=tr&subscribe=no',
    pl: '/marketplace/login/google?locale=pl&subscribe=no',
    pt: '/marketplace/login/google?locale=pt&subscribe=no'
  },
  facebookLogin: {
    en: '/marketplace/login/facebook?locale=en&subscribe=no',
    es: '/marketplace/login/facebook?locale=es&subscribe=no',
    it: '/marketplace/login/facebook?locale=it&subscribe=no',
    fr: '/marketplace/login/facebook?locale=fr&subscribe=no',
    de: '/marketplace/login/facebook?locale=de&subscribe=no',
    tr: '/marketplace/login/facebook?locale=tr&subscribe=no',
    pl: '/marketplace/login/facebook?locale=pl&subscribe=no',
    pt: '/marketplace/login/facebook?locale=pt&subscribe=no'
  },
  contactUs: {
    en: '/contact-us',
    es: '/es/contacto',
    it: '/it/contattaci',
    fr: '/fr/nous-contacter',
    de: '/de/kontaktieren-sie-uns',
    tr: '/tr/bize-ulasin',
    pl: '/pl/skontaktuj-sie-z-nami',
    pt: '/pt/contacte-nos'
  }
};
