import PropTypes from 'prop-types';

const QuoteIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="38"
    fill="none"
    viewBox="0 0 45 38"
    className="quote-icon"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M17.879 19.389h-6.705v-1.933c0-5.92 2.235-9.503 6.705-10.751V0C5.959 1.047 0 8.96 0 23.738v13.53h17.879V19.389zm27.121 0h-6.705v-1.933c0-5.92 2.235-9.503 6.705-10.751V0C33.08 1.047 27.121 8.96 27.121 23.738v13.53H45V19.389z"
      clipRule="evenodd"
    />
  </svg>
);

QuoteIcon.propTypes = {
  color: PropTypes.string
};

QuoteIcon.defaultProps = {
  color: 'purple'
};

export default QuoteIcon;
