import PropTypes from 'prop-types';

import { useHeaderLinks } from '../../context/ExternalLink';

import LoginButton from '../LoginButtonComponent';

import themeShape, { DARK_THEME, LIGHT_THEME } from './shapes/theme.shape';

const HeaderLogin = ({
  color = DARK_THEME,
  isMobile = false,
  newHeaderSignupLogin = false
}) => {
  const headerLinks = useHeaderLinks();

  return (
    <LoginButton
      logoutPath={headerLinks.logout}
      userAreaPath={headerLinks.myRequests}
      searchPreferencePath={headerLinks.searchPreferences}
      myProfilePath={headerLinks.myProfile}
      myReferralsPath={headerLinks.myReferrals}
      myPaymentsPath={headerLinks.myPayments}
      color={color}
      isMobile={isMobile}
      newHeaderSignupLogin={newHeaderSignupLogin}
      typeMenu={color === LIGHT_THEME ? 'menu-transparent' : 'menu-white'}
    />
  );
};

HeaderLogin.propTypes = {
  color: themeShape,
  isMobile: PropTypes.bool,
  newHeaderSignupLogin: PropTypes.bool
};

export default HeaderLogin;
