const BASE_LINKS = {
  en: '/',
  es: '/es',
  it: '/it',
  fr: '/fr',
  de: '/de',
  pt: '/pt'
};

export const getBaseLinks = () => BASE_LINKS;

export default {
  getBaseLinks
};
