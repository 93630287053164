import addHours from 'date-fns/addHours';
import differenceInHours from 'date-fns/differenceInHours';

import { setItem, getItem } from '../../../utils/localStorage';

import { OFFER_BOX_INFO, OFFER_BOX_EXPIRATION_TIME } from './constants';

export const getRemainingOfferHours = offerBoxInfo =>
  differenceInHours(new Date(offerBoxInfo.expiresOn), new Date());

export const getDefaultOfferBoxInfo = () => ({
  expiresOn: addHours(new Date(), OFFER_BOX_EXPIRATION_TIME),
  hasInteracted: false,
  hasClosedSignupModal: false
});

export const getOfferBoxInfo = () => {
  const offerBoxStorageInfo = getItem(OFFER_BOX_INFO);
  return JSON.parse(offerBoxStorageInfo);
};

export const updateOfferBoxInfo = newOfferBoxInfo => {
  setItem(
    OFFER_BOX_INFO,
    JSON.stringify({
      ...getOfferBoxInfo(),
      ...newOfferBoxInfo
    })
  );
};
