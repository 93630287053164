import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  HEADER_LINKS_KEY,
  FOOTER_LINKS_KEY,
  BASE_LINKS_KEY,
  getLinksContext
} from '../data/links/externalLinks';

const ExternalLinkContext = createContext();
const useExternalLinksContext = key => {
  const externalLinks = useContext(ExternalLinkContext);

  return externalLinks ? externalLinks[key] : null;
};

export const ExternalLinkProvider = ({ children, currentLocale }) => {
  const externalLinks = useExternalLinksContext(BASE_LINKS_KEY);

  return externalLinks ? (
    children
  ) : (
    <ExternalLinkContext.Provider value={getLinksContext(currentLocale)}>
      {children}
    </ExternalLinkContext.Provider>
  );
};

ExternalLinkProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  currentLocale: PropTypes.string.isRequired
};

export const useHeaderLinks = () => useExternalLinksContext(HEADER_LINKS_KEY);
export const useFooterLinks = () => useExternalLinksContext(FOOTER_LINKS_KEY);
export const useBaseLinks = () => useExternalLinksContext(BASE_LINKS_KEY);

export default {
  ExternalLinkProvider,
  useHeaderLinks,
  useFooterLinks,
  useBaseLinks
};
