import { useEffect } from 'react';

import { Guarantees, Section } from '@spotahome/ui-library';
import Metrics from '@spotahome/soyuz-tracking';

import { useInView } from 'react-intersection-observer';

import './GuaranteeSection.scss';
import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

const GuaranteeSection = () => {
  const [guaranteeRef, guaranteeInView] = useInView({
    triggerOnce: true,
    rootMargin: '100px'
  });

  useEffect(() => {
    if (guaranteeInView) {
      Metrics.ga.sendEvent('Home', 'guarantees', 'seen');
    }
  }, [guaranteeInView]);

  return (
    <section className="guarantee-section" ref={guaranteeRef}>
      <Section background="light">
        <Guarantees />
      </Section>
    </section>
  );
};

export default withInViewEvent(LANDING_SECTIONS.guarantee)(GuaranteeSection);
