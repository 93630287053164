import { trans } from '@spotahome/soyuz/client';
import { Colors } from '@spotahome/ui-library';

import FeatureBoxList from '@spotahome/landings-common/src/components/FeatureBoxList';
import MobileIcon from '@spotahome/landings-common/src/components/icons/MobileIcon';
import HomeCheckIcon from '@spotahome/landings-common/src/components/icons/HomeCheckIcon';
import CameraIcon from '@spotahome/landings-common/src/components/icons/CameraIcon';
import ShieldPersonIcon from '@spotahome/landings-common/src/components/icons/ShieldPersonIcon';
import TextBlock from '@spotahome/landings-common/src/components/TextBlock';

import './FeatureBoxSection.scss';

const FeatureBoxSection = () => {
  const featureList = [
    {
      icon: <MobileIcon color={Colors.secondary} />,
      description: 'home.tenant.feature-box-section-first-item-rebranding'
    },
    {
      icon: <HomeCheckIcon theme="newTheme" />,
      description: 'home.tenant.feature-box-section-second-item-rebranding'
    },
    {
      icon: <CameraIcon theme="newTheme" />,
      description: 'home.tenant.feature-box-section-third-item-rebranding'
    },
    {
      icon: <ShieldPersonIcon color={Colors.secondary} />,
      description: 'home.tenant.feature-box-section-fourth-item-rebranding'
    }
  ];

  return (
    <section className="feature-box-section">
      <TextBlock
        title={trans('home.tenant.feature-box-section-title-rebranding')}
        alignTo="center"
        titleSize="big"
        contentSize="big"
        style={{ maxWidth: '700px', margin: 'auto' }}
      />
      <FeatureBoxList featureList={featureList} />
    </section>
  );
};

export default FeatureBoxSection;
