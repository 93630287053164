import { useState } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';
import Metrics from '@spotahome/soyuz-tracking';

import { Modal, CloseButton } from '@spotahome/ui-library';

import classNames from 'classnames';

import { scrollVerticallyTo } from '../utils/scroller';
import './FeatureBox.scss';

const FeatureBox = ({
  icon,
  description,
  title,
  learnMore,
  learnMoreLink,
  className,
  iconClassName,
  titleClassName,
  descriptionClassName
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const containerClasses = classNames('feature-box', className);
  const iconClasses = classNames('feature-box__icon', iconClassName);
  const titleClasses = classNames('feature-box__title', titleClassName);
  const descriptionClasses = classNames(
    'feature-box__description',
    descriptionClassName
  );

  const handleModalOnClose = () => setModalOpen(false);
  const handleModalOnOpen = () => setModalOpen(true);

  const handleLearnMoreLink = () => {
    Metrics.ga.sendEvent(
      'landlord_landing',
      'feature_read_more_link',
      'guaranteed.payments'
    );

    scrollVerticallyTo({
      attribute: {
        name: 'scroll',
        value: learnMoreLink
      }
    });
  };

  return (
    <>
      <div className={containerClasses}>
        <div className={iconClasses}>{icon}</div>
        {title && <h3 className={titleClasses}>{title}</h3>}
        <div className={descriptionClasses}>{description}</div>
        {learnMoreLink && (
          <button
            type="button"
            onClick={handleLearnMoreLink}
            className="feature-box__learn-more"
          >
            {trans('lister.landing.alternative.show-more')}
          </button>
        )}
        {learnMore && (
          <button
            type="button"
            onClick={handleModalOnOpen}
            className="feature-box__learn-more"
          >
            {trans('lister.landing.alternative.show-more')}
          </button>
        )}
      </div>
      {learnMore && (
        <Modal
          className="feature-box__modal"
          isOpen={isModalOpen}
          onRequestClose={handleModalOnClose}
        >
          <CloseButton
            className="feature-box__modal--closed"
            size="small"
            onClick={handleModalOnClose}
          />
          {learnMore}
        </Modal>
      )}
    </>
  );
};

FeatureBox.propTypes = {
  icon: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string,
  learnMore: PropTypes.node,
  learnMoreLink: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  descriptionClassName: PropTypes.string
};

FeatureBox.defaultProps = {
  title: '',
  learnMore: null,
  learnMoreLink: null,
  className: null,
  iconClassName: null,
  titleClassName: null,
  descriptionClassName: null
};

export default FeatureBox;
