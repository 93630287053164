import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';

import FeatureBox from './FeatureBox';

import './FeatureBoxList.scss';

const FeatureBoxList = ({ featureList }) => (
  <div className="feature-box-list">
    <div className="feature-box-list__wrapper">
      {featureList.map(feature => (
        <div key={feature.description} className="feature-box-list__item">
          <FeatureBox
            icon={feature.icon}
            title={trans(feature.title)}
            description={trans(feature.description)}
          />
        </div>
      ))}
    </div>
  </div>
);

FeatureBoxList.propTypes = {
  featureList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      title: PropTypes.string,
      description: PropTypes.string.isRequired
    })
  )
};

FeatureBoxList.defaultProps = {
  featureList: []
};
export default FeatureBoxList;
