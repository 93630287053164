import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import styles from '../Footer.module.scss';

const FooterPaymentMethod = props => (
  <LazyLoad offset={100} once>
    <img className={styles['footer__payment-method']} {...props} />
  </LazyLoad>
);

FooterPaymentMethod.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default FooterPaymentMethod;
