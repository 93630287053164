/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { trans, useSoyuzLocales } from '@spotahome/soyuz/client';

import { Section, TrustpilotWidget, MediaQuery } from '@spotahome/ui-library';
import { TEMPLATE_IDS } from '@spotahome/ui-library/src/tenant/components/TrustpilotWidget/TrustpilotWidget';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import { CarouselArrows } from '../MultipleItemsCarousel';

import Testimonial from './Testimonial';

import './TestimonialsCarousel.scss';

const { isWideScreen, isBiggerThanMobile } = MediaQuery;

const RATINGS = [
  {
    id: 'testimonial-1',
    stars: 4,
    comment: 'landingpage.testimonials.comment.1',
    author: 'Luise Aymar',
    country: 'Germany'
  },
  {
    id: 'testimonial-2',
    stars: 5,
    comment: 'landingpage.testimonials.comment.2',
    author: 'Diego Ruiz',
    country: 'Bolivia'
  },
  {
    id: 'testimonial-3',
    stars: 4,
    comment: 'landingpage.testimonials.comment.3',
    author: 'Leila Helal',
    country: 'Uruguay'
  }
];

const TestimonialsCarousel = () => {
  const [page, setPage] = useState(0);
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(95);
  const { current } = useSoyuzLocales();

  useEffect(() => {
    if (isWideScreen()) {
      setCenterSlidePercentage(50);
      return;
    }
    if (isBiggerThanMobile()) {
      setCenterSlidePercentage(80);
    }
  }, []);

  return (
    <Section background="light" className="testimonials-carousel">
      <div className="testimonials-carousel">
        <div className="testimonials-carousel__header">
          <h3 className="testimonials-carousel__title">
            {trans('landingpage.label.testimonials.carousel.title')}
          </h3>
          <div className="testimonials-carousel__arrows">
            <CarouselArrows
              page={page}
              setPage={setPage}
              maxPage={RATINGS.length}
              isRedesign
            />
          </div>
        </div>

        <div className="testimonials-carousel__carousel">
          <Carousel
            showThumbs={false}
            showArrows={false}
            selectedItem={page}
            showIndicators={false}
            showStatus={false}
            centerSlidePercentage={centerSlidePercentage}
            centerMode
            autoPlay={false}
          >
            {RATINGS.map(testimonial => {
              const props = {
                ...testimonial,
                comment: trans(testimonial.comment)
              };
              return (
                <Testimonial
                  key={testimonial.id}
                  className="testimonials-carousel__testimonial"
                  {...props}
                />
              );
            })}
          </Carousel>
        </div>

        <TrustpilotWidget
          locale={current}
          className="testimonials-carousel__trustpilot"
          template={TEMPLATE_IDS.MICRO_STAR}
        />
      </div>
    </Section>
  );
};

export default withInViewEvent(LANDING_SECTIONS.cardTestimonialQuote)(
  TestimonialsCarousel
);
