import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import Button from '../../Button';

import { LOGIN, SIGNUP, FORGOTTEN_PASSWORD } from '../constants';

import styles from './LoginSignupModalToggle.module.scss';

const LoginSignupModalToggle = ({
  action,
  onToggleAction,
  size = 'regular'
}) => (
  <span
    className={`${styles['login-signup-modal-toggle']} ${
      styles[`login-signup-modal-toggle--${size}`]
    }`}
  >
    <p className={styles['login-signup-modal-toggle__text']}>
      {trans(`${action}.account_text`)}{' '}
      <Button
        color="link"
        className={styles['login-signup-modal-toggle__toggle']}
        onClick={onToggleAction}
        data-test={`loginSignupModal_toggle_${action}`}
      >
        {trans(`${action}.account_claim`)}
      </Button>
    </p>
  </span>
);

LoginSignupModalToggle.propTypes = {
  action: PropTypes.oneOf([LOGIN, SIGNUP, FORGOTTEN_PASSWORD]).isRequired,
  onToggleAction: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['regular', 'small'])
};

export default LoginSignupModalToggle;
