import classNames from 'classnames';
import { trans } from '@spotahome/soyuz/client';
import PropTypes from 'prop-types';

import './HistoricalMetrics.scss';

const EXPERIENCE = [
  {
    title: 'historical.bookings.number',
    text: 'historical.bookings'
  },
  {
    title: 'historical.landlords.number',
    text: 'historical.landlords'
  },
  {
    title: 'historical.homes.number',
    text: 'historical.homes'
  }
];

const MetricsItem = ({ title, text, numberClassName, textClassName }) => {
  const numberClasses = classNames(
    'historical-metrics__number',
    numberClassName
  );

  const textClasses = classNames('historical-metrics__text', textClassName);

  return (
    <div className="historical-metrics__section">
      <div className={numberClasses}>{trans(title)}</div>
      <div className={textClasses}>{trans(text)}</div>
    </div>
  );
};

MetricsItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  numberClassName: PropTypes.string.isRequired,
  textClassName: PropTypes.string.isRequired
};

const HistoricalMetrics = ({ className, numberClassName, textClassName }) => {
  const classes = classNames('historical-metrics', className);

  return (
    <div className={classes}>
      <div className="historical-metrics__content">
        {EXPERIENCE.map(({ title, text }) => (
          <MetricsItem
            title={title}
            text={text}
            key={title}
            numberClassName={numberClassName}
            textClassName={textClassName}
          />
        ))}
      </div>
    </div>
  );
};

HistoricalMetrics.defaultProps = {
  className: '',
  numberClassName: '',
  textClassName: ''
};

HistoricalMetrics.propTypes = {
  className: PropTypes.string,
  numberClassName: PropTypes.string,
  textClassName: PropTypes.string
};

export default HistoricalMetrics;
