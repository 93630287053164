import { trans, getResourcePath } from '@spotahome/soyuz/client';

import TestimonialQuote from '@spotahome/landings-common/src/components/TestimonialQuote';
import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import './QuoteSection.scss';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

const image = {
  src: getResourcePath(`/images/home/quote-img.jpg`),
  alt: trans('home.tenant.quote')
};

const QuoteSection = () => (
  <section className="quote-section">
    <TestimonialQuote
      theme="pink"
      image={image}
      message={trans('home.tenant.quote')}
      source={trans('home.tenant.quote.source')}
    />
  </section>
);

export default withInViewEvent(LANDING_SECTIONS.quote)(QuoteSection);
