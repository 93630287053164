import PropTypes from 'prop-types';

import { useSoyuzLocales } from '@spotahome/soyuz/client';

import { HeaderContainer as HeaderContainerComponent } from '@spotahome/ui-library';

import { useLocaleChange } from '@spotahome/ui-library/src/hooks/useLocaleChange';

const HeaderContainer = ({
  color = 'dark',
  tracking = '',
  links = {},
  ...restProps
}) => {
  const handleOnLocaleChange = useLocaleChange();
  const { current } = useSoyuzLocales();

  return (
    <HeaderContainerComponent
      transparent
      color={color}
      locales={{ current, onChange: handleOnLocaleChange }}
      showLogin
      isContactUsEnabled
      tracking={tracking}
      links={links}
      {...restProps}
    />
  );
};

HeaderContainer.propTypes = {
  color: PropTypes.string,
  tracking: PropTypes.string,
  links: PropTypes.shape({
    listYourProperty: PropTypes.oneOf([PropTypes.bool, PropTypes.shape({})]),
    tenantLandingPage: PropTypes.oneOf([PropTypes.bool, PropTypes.shape({})])
  })
};

export default HeaderContainer;
