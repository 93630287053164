import PropTypes from 'prop-types';

import { Colors } from '@spotahome/ui-library';

const themes = {
  oldTheme: '#35c2a9',
  purpleLight: '#880068',
  newTheme: Colors.secondary
};

const CameraIcon = ({ theme, ...props }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.427 37.33H41.377C41.377 38.22 41.031 39.01 40.437 39.61C39.843 40.2 39.051 40.55 38.16 40.55H5.84102C4.95102 40.55 4.15902 40.2 3.56502 39.61C2.97102 39.01 2.62402 38.22 2.62402 37.33V11.5C2.62402 10.61 2.97102 9.81 3.56502 9.22C4.15902 8.63 4.95102 8.28 5.84102 8.28H13.243C13.985 8.28 14.578 7.69 14.578 6.94V5.51C14.578 4.96 14.777 4.47 15.123 4.12C15.47 3.78 15.964 3.58 16.509 3.58H27.542C28.086 3.58 28.581 3.78 28.928 4.12C29.275 4.47 29.472 4.96 29.472 5.51V6.94C29.472 7.69 30.067 8.28 30.809 8.28H38.209C39.101 8.28 39.893 8.63 40.487 9.22C41.08 9.81 41.427 10.61 41.427 11.5V37.33ZM42.268 7.39C41.229 6.3 39.793 5.66 38.16 5.66H32.096V5.56C32.096 4.32 31.601 3.13 30.759 2.34C29.918 1.5 28.78 1 27.542 1H16.459C15.172 1 14.035 1.5 13.193 2.34C12.351 3.18 11.856 4.32 11.856 5.56V5.66H5.841C4.208 5.66 2.773 6.3 1.734 7.39C0.694 8.43 0 9.91 0 11.5V37.33C0 38.96 0.644 40.4 1.734 41.44C2.773 42.48 4.258 43.17 5.841 43.17H38.699C40.331 43.17 41.229 42.53 42.268 41.44C43.308 40.4 44 38.92 44 37.33V11.5C44 9.86 43.358 8.43 42.268 7.39Z"
        fill={themes[theme]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6669 10.79C37.7699 10.79 38.6629 11.68 38.6629 12.78C38.6629 13.89 37.7699 14.78 36.6669 14.78C35.5649 14.78 34.6709 13.89 34.6709 12.78C34.6709 11.68 35.5649 10.79 36.6669 10.79Z"
        fill={themes[theme]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9686 13.8601C16.1736 13.8601 11.4756 18.5601 11.4756 24.3501C11.4756 30.1501 16.1736 34.8401 21.9686 34.8401C27.7646 34.8401 32.4616 30.1501 32.4616 24.3501C32.4616 18.5601 27.7646 13.8601 21.9686 13.8601ZM21.9685 16.2801C26.4185 16.2801 30.0375 19.9001 30.0375 24.3501C30.0375 28.8001 26.4185 32.4201 21.9685 32.4201C17.5195 32.4201 13.8995 28.8001 13.8995 24.3501C13.8995 19.9001 17.5195 16.2801 21.9685 16.2801Z"
        fill={themes[theme]}
      />
    </svg>
  );
};

CameraIcon.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

CameraIcon.defaultProps = {
  theme: 'purpleLight'
};

export default CameraIcon;
