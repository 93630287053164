const Youtube = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="22"
    viewBox="0 0 31 22"
    className={className}
  >
    <path d="M30.581 4.73s-.325-2.2-1.193-3.08C28.194.44 26.892.44 26.35.33 22.01 0 15.5 0 15.5 0S8.99 0 4.758.33c-.65.11-1.953.11-3.038 1.32C.744 2.64.527 4.73.527 4.73S.201 7.26.201 9.79v2.42c0 2.53.326 5.06.326 5.06s.326 2.2 1.193 3.08c1.194 1.21 2.713 1.21 3.364 1.32C7.471 21.89 15.5 22 15.5 22s6.51 0 10.85-.33c.651-.11 1.953-.11 3.038-1.32.976-.99 1.193-3.08 1.193-3.08s.326-2.53.326-5.06V9.79c0-2.53-.326-5.06-.326-5.06zM12.354 15.07v-8.8l8.354 4.4-8.354 4.4z" />
  </svg>
);

export default Youtube;
