import PropTypes from 'prop-types';

import { ExternalLinkProvider } from '../../context/ExternalLink';

import HeaderContainerComponent from './HeaderContainer';

export { LIGHT_THEME, DARK_THEME, GREEN_THEME } from './shapes/theme.shape';

const HeaderContainer = ({ locales, ...restProps }) => (
  <ExternalLinkProvider currentLocale={locales.current}>
    <HeaderContainerComponent locales={locales} {...restProps} />
  </ExternalLinkProvider>
);

HeaderContainer.propTypes = {
  locales: PropTypes.shape({
    current: PropTypes.string
  }).isRequired
};

export default HeaderContainer;
