import { useEffect, useRef } from 'react';
import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';
import { trans } from '@spotahome/soyuz/client';
import classNames from 'classnames';
import { isBiggerThanPhablet } from '@spotahome/ui-library/src/tenant/components/utils/breakpoints';
import { Link } from '@spotahome/ui-library';

import './GuaranteeSectionRedesign.scss';

const PUBLIC_ASSETS_LINK =
  'https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/';

const GUARANTEES = [
  {
    title: 'guarantees.support.title',
    text: 'guarantees.support.text',
    icon: 'support-guarantee-icon.svg',
    backgroundColor: 'white'
  },
  {
    title: 'guarantees.coverage.title',
    text: 'guarantees.coverage.text',
    icon: 'coverage-guarantee-icon.svg',
    backgroundColor: 'grey',
    link: { copy: 'guarantees.coverage.link', href: '#our-guarantee' }
  },
  {
    title: 'guarantees.price.title',
    text: 'guarantees.price.text',
    icon: 'price-guarantee-icon.svg',
    backgroundColor: 'blue'
  },
  {
    title: 'guarantees.online.title',
    text: 'guarantees.online.text',
    icon: 'online-guarantee-icon.svg',
    backgroundColor: 'pink'
  }
];

const GuaranteeSectionRedesign = () => {
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const index = sectionRefs.current.indexOf(entry.target);
          if (entry.isIntersecting) {
            sectionRefs.current.forEach((section, i) => {
              if (i <= index) {
                section.classList.add(
                  'guarantee-section-redesign__guarantee--is-sticky'
                );
              } else {
                section.classList.remove(
                  'guarantee-section-redesign__guarantee--is-sticky'
                );
              }
            });
          }
        });
      },
      {
        threshold: 0.1
      }
    );

    sectionRefs.current.forEach(section => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const guarantees = GUARANTEES.map(
    ({ title, text, link, icon, backgroundColor }, i) => {
      const isEven = i % 2 === 0;

      const guaranteeClasses = classNames(
        'guarantee-section-redesign__guarantee',
        `guarantee-section-redesign__guarantee--${backgroundColor}-bg`
      );

      const contentWrapperClassess = classNames(
        'guarantee-section-redesign__content-wrapper',
        {
          'guarantee-section-redesign__guarantee--reverse':
            !isEven && isBiggerThanPhablet()
        }
      );

      return (
        <div
          className={guaranteeClasses}
          key={title}
          ref={el => (sectionRefs.current[i] = el)}
        >
          <div className={contentWrapperClassess}>
            <div className="guarantee-section-redesign__copies">
              <div className="guarantee-section-redesign__title">
                {trans(title)}
              </div>
              <div className="guarantee-section-redesign__subtitle">
                <div>{trans(text)}</div>
                {link && (
                  <Link underlined href={link.href}>
                    {trans(link.copy)}
                  </Link>
                )}
              </div>
            </div>
            <img
              src={`${PUBLIC_ASSETS_LINK}${icon}`}
              width="500"
              alt={trans(title)}
            />
          </div>
        </div>
      );
    }
  );

  return <div className="guarantee-section-redesign">{guarantees}</div>;
};

export default withInViewEvent(LANDING_SECTIONS.guarantee)(
  GuaranteeSectionRedesign
);
