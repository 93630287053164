import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import FooterLink from './components/FooterLink';
import FooterBlock from './components/FooterBlock';
import FooterBlockList from './components/FooterBlockList';

const LanguageFooter = props => (
  <FooterBlock>
    <FooterBlockList isInline centered>
      <li>{trans('footer.label.languages')}:</li>
      {Object.keys(props.routes).map(route => (
        <FooterLink
          linkTheme={props.linkTheme}
          key={route}
          href={props.routes[route]}
          type="secondary"
        >
          {trans(`locale.${route}`)}
        </FooterLink>
      ))}
    </FooterBlockList>
  </FooterBlock>
);

LanguageFooter.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  linkTheme: PropTypes.string.isRequired
};

export default LanguageFooter;
