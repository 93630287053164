const BASE_PATH = 'https://static.spotahome.com';

const FOOTER_PATH = 'footer_image';
const LOGIN_PATH = 'login_image';

const getImagePath = (path, name = '') => `${BASE_PATH}/${path}/${name}`;

export const getFooterImagePath = imageName =>
  getImagePath(FOOTER_PATH, imageName);

export const getLoginImagePath = imageName =>
  getImagePath(LOGIN_PATH, imageName);

export default { getFooterImagePath, getLoginImagePath };
