import HEADER_LINKS from './header';
import FOOTER_LINKS from './footer';
import { getBaseLinks } from './base';

export const HEADER_LINKS_KEY = 'headerLinks';
export const FOOTER_LINKS_KEY = 'footerLinks';
export const BASE_LINKS_KEY = 'pathLinks';

const filterByLocale = (links, currentLocale) =>
  Object.entries(links).reduce(
    (acc, [key, link]) => ({
      ...acc,
      [key]: link[currentLocale]
    }),
    {}
  );

export const getLinksContext = currentLocale => ({
  [HEADER_LINKS_KEY]: filterByLocale(HEADER_LINKS, currentLocale),
  [FOOTER_LINKS_KEY]: filterByLocale(FOOTER_LINKS, currentLocale),
  [BASE_LINKS_KEY]: getBaseLinks()
});

export default {
  HEADER_LINKS_KEY,
  FOOTER_LINKS_KEY,
  BASE_LINKS_KEY,
  getLinksContext
};
