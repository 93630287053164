const Eye = () => (
  <svg
    width="60"
    height="48"
    viewBox="0 0 60 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.2735 10.1376C46.3574 3.64659 38.3334 0 29.9667 0C21.6 0 13.576 3.64659 7.6599 10.1376C4.13441 14.0056 1.52953 18.8993 6.19851e-08 24L60 24C58.4705 18.8993 55.799 14.0056 52.2735 10.1376Z"
      fill="#F4EFED"
    />
    <path
      d="M7.72647 37.8624C13.6426 44.3534 21.6666 48 30.0333 48C38.4 48 46.424 44.3534 52.3401 37.8624C55.8656 33.9944 58.4705 29.1007 60 24L1.80725e-06 24C1.52953 29.1007 4.20098 33.9944 7.72647 37.8624Z"
      fill="#F4EFED"
    />
    <ellipse cx="30" cy="24.2166" rx="9.47368" ry="9.47368" fill="#0A0777" />
  </svg>
);

export default Eye;
