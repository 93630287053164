import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useFooterLinks, useBaseLinks } from '../../context/ExternalLink';

import FooterSection from './components/FooterSection';
import FooterSeparator from './components/FooterSeparator';

import SpotahomeFooter from './SpotahomeFooter';
import TenantFooter from './TenantFooter';
import LandlordFooter from './LandlordFooter';
import HelpFooter from './HelpFooter';
import SocialFooter from './SocialFooter';
import AppFooter from './AppFooter';
import PaymentFooter from './PaymentFooter';
import TrustIconsFooter from './TrustIconsFooter';
import CopyrightFooter from './CopyrightFooter';
import LanguageFooter from './LanguageFooter';
import FooterBlock from './components/FooterBlock';

import styles from './Footer.module.scss';

export const themeOptions = {
  green: 'green',
  blue: 'blue',
  beige: 'beige'
};

const themeConfig = {
  green: {
    topClass: 'footer--green',
    bottomClass: 'footer__disclaimer--green',
    linkTheme: 'light'
  },
  blue: {
    topClass: 'footer--blue',
    bottomClass: 'footer__disclaimer--blue',
    linkTheme: 'light_underline'
  },
  beige: {
    topClass: 'footer--beige',
    bottomClass: 'footer__disclaimer--beige',
    linkTheme: 'beige'
  }
};

const Footer = memo(
  ({ apps, locales, icons, theme, showContactInfo }) => {
    const externalLinks = useFooterLinks();
    const localeLinks = useBaseLinks();

    const { topClass, bottomClass, linkTheme } =
      themeConfig[theme] || themeConfig.green;

    return (
      <footer>
        <section className={classNames(styles.footer, styles[topClass])}>
          <FooterSection>
            <SpotahomeFooter linkTheme={linkTheme} {...externalLinks} />

            <FooterBlock>
              <TenantFooter linkTheme={linkTheme} {...externalLinks} />
              <LandlordFooter linkTheme={linkTheme} {...externalLinks} />
            </FooterBlock>

            <HelpFooter
              linkTheme={linkTheme}
              {...externalLinks}
              locales={locales}
              showContactInfo={showContactInfo}
            />
            <SocialFooter linkTheme={linkTheme} />
          </FooterSection>

          <FooterSeparator />

          <FooterSection>
            <AppFooter linkTheme={linkTheme} {...apps} />
            <PaymentFooter />
            <TrustIconsFooter linkTheme={linkTheme} {...icons} />
          </FooterSection>
        </section>
        <section
          className={classNames(styles.footer__disclaimer, styles[bottomClass])}
        >
          <FooterSection>
            <CopyrightFooter />
            <LanguageFooter
              linkTheme={linkTheme}
              routes={locales.routes || localeLinks}
            />
          </FooterSection>
        </section>
      </footer>
    );
  }
);

Footer.propTypes = {
  locales: PropTypes.shape({
    current: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string),
    routes: PropTypes.objectOf(PropTypes.string),
    onChange: PropTypes.func.isRequired
  }).isRequired,
  apps: PropTypes.shape({
    android: PropTypes.object,
    ios: PropTypes.object
  }),
  icons: PropTypes.shape({
    prs: PropTypes.object
  }),
  theme: PropTypes.oneOf(Object.keys(themeOptions)),
  showContactInfo: PropTypes.bool
};

Footer.defaultProps = {
  apps: {},
  icons: {},
  theme: themeOptions.blue,
  showContactInfo: false
};

export default Footer;
