import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  trans,
  useSoyuzLocales,
  getResourcePath,
  useSoyuzExperiment
} from '@spotahome/soyuz/client';
import {
  InfoBanner,
  MediaQuery,
  TrustpilotWidget
} from '@spotahome/ui-library';

import classNames from 'classnames';

import HeroContainer from '@spotahome/landings-common/src/components/HeroContainer';
import HeaderContainer from '@spotahome/landings-common/src/components/HeaderContainer';
import HistoricalMetrics from '@spotahome/landings-common/src/components/HistoricalMetrics';
import Tracking from '@spotahome/soyuz-tracking';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import CityAutocompleter from './CityAutocompleter';

import './Hero.scss';
import { DSIcon } from '@spotahome/ui-library/base';

const { isMobileScreen, isPhabletScreen, isTabletScreen } = MediaQuery;

const HeroTenant = ({ cityList, deviceType }) => {
  const [isDark, setIsDark] = useState(true);
  useEffect(() => {
    setIsDark(isMobileScreen() || isPhabletScreen() || isTabletScreen());
  }, []);
  const { current: locale } = useSoyuzLocales();

  const handleTrustpilotClick = () => {
    Tracking.ga.sendEvent('Home', 'trustpilot-widget', 'click');
  };

  const isHomepageMonumentsImage = deviceType === 'desktop';

  const heroTenantWrapperClasses = classNames('hero-tenant__wrapper', {
    'hero-tenant__wrapper--background-image': isHomepageMonumentsImage
  });

  const isBestPriceExp =
    useSoyuzExperiment('BestPriceGuaranteed').get('behaviour') === 'variant';

  const containerClasses = classNames('hero-tenant__container', {
    'hero-tenant__container-higher': isBestPriceExp
  });

  return (
    <section>
      <HeroContainer>
        <div className="hero-tenant__header-wrapper">
          <HeaderContainer
            color={{
              foregroundColor: isDark ? 'dark' : 'light',
              logoColor: isDark ? 'dark' : 'light'
            }}
            tracking="ga-home-tenant"
          />
        </div>
        <div className={heroTenantWrapperClasses}>
          <div className={containerClasses}>
            <div className="hero-tenant__info">
              <div className="hero-tenant__info-content">
                <h1 className="hero-tenant__info-title">
                  {trans('home.tenant.hero.title.experiment')}
                </h1>
                <p className="hero-tenant__info-subtitle">
                  {trans('home.tenant.hero.subtitle.experiment')}
                </p>
                {isBestPriceExp && (
                  <div className="hero-tenant__best-price-wrapper">
                    <InfoBanner
                      className="hero-tenant__best-price"
                      theme="suggestion-light"
                      closable={false}
                      icon={<DSIcon name="credit-card" />}
                      content={
                        <span>{trans('best.price.listing.banner.title')}</span>
                      }
                    />
                  </div>
                )}
                <CityAutocompleter
                  cities={cityList}
                  deviceType={deviceType}
                  className="hero-tenant__city-selector"
                />
              </div>
            </div>
            <div className="hero-tenant__container-bottom hero-tenant__container-bottom--centered">
              {deviceType === 'desktop' && (
                <img
                  className="hero-tenant__monument"
                  src={getResourcePath('/images/home/monuments.svg')}
                  alt="Momument"
                  rel="preload"
                  width="500"
                />
              )}
              <div className="hero-tenant__trustpilot-wrapper">
                <TrustpilotWidget
                  locale={locale}
                  onClick={handleTrustpilotClick}
                />
              </div>
            </div>
          </div>
        </div>
      </HeroContainer>
      <HistoricalMetrics />
    </section>
  );
};

HeroTenant.propTypes = {
  cityList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deviceType: PropTypes.oneOf(['smartphone', 'tablet', 'desktop']).isRequired
};

export default withInViewEvent(LANDING_SECTIONS.hero)(HeroTenant);
