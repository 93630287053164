import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import styles from '../Footer.module.scss';

const FooterStoreIcon = props => (
  <LazyLoad offset={100} once>
    <img className={styles['footer__store-icon']} {...props} />
  </LazyLoad>
);

FooterStoreIcon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default FooterStoreIcon;
