import PropTypes from 'prop-types';
import classNames from 'classnames';

import LazyLoad from 'react-lazyload';

import QuoteIcon from './icons/QuoteIcon';

import './TestimonialQuote.scss';

export const themes = {
  green: '#35c2a9',
  pink: '#ffccef',
  purple: '#880068'
};

const TestimonialQuote = ({
  theme,
  image,
  message,
  source,
  quoteSize,
  insideCard
}) => {
  const TestimonialQuoteCN = classNames('testimonial-quote', {
    'testimonial-quote--card': insideCard,
    'testimonial-quote--big': quoteSize === 'big'
  });

  return (
    <div className={TestimonialQuoteCN}>
      {image && (
        <div className="testimonial-quote__image">
          <LazyLoad height={100} offset={[100, 100]} once>
            <img src={image.src} alt={image.alt} />
          </LazyLoad>
        </div>
      )}
      <div className="testimonial-quote__content">
        <QuoteIcon color={themes[theme]} />
        <p className="testimonial-quote__message">{message}</p>
        <p className="testimonial-quote__source">{source}</p>
      </div>
    </div>
  );
};

TestimonialQuote.propTypes = {
  theme: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  message: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  quoteSize: PropTypes.oneOf(['big', 'medium']),
  insideCard: PropTypes.bool
};

TestimonialQuote.defaultProps = {
  theme: 'purple',
  image: null,
  quoteSize: 'big',
  insideCard: false
};

export default TestimonialQuote;
