import PropTypes from 'prop-types';

import { ExternalLinkProvider } from '../../context/ExternalLink';

import Footer from './Footer';

const FooterContainer = ({ locales, ...restProps }) => (
  <ExternalLinkProvider currentLocale={locales.current}>
    <Footer locales={locales} {...restProps} />
  </ExternalLinkProvider>
);

FooterContainer.propTypes = {
  locales: PropTypes.shape({
    current: PropTypes.string
  }).isRequired
};

export default FooterContainer;
