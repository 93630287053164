import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { MediaQuery } from '@spotahome/ui-library';
import classNames from 'classnames';

import { trans, getPagePath, getResourcePath } from '@spotahome/soyuz/client';

import { TOP_CITIES } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

// styles
import './City.scss';

const CityListItem = ({ href, transKey, cityName }) => (
  <li className="city-count__list-item">
    <a href={href}>{trans(transKey, { city: cityName })}</a>
  </li>
);

CityListItem.propTypes = {
  href: PropTypes.string.isRequired,
  transKey: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired
};

const City = props => {
  const { id } = props;
  const cityName = trans(`city.${id}`);
  const rentalsInCityTrans = trans('seo.labels.rentals.in', { city: cityName });

  const path = getPagePath('realestate.search', {
    params: { city: id }
  });

  const { isMobileScreen, isPhabletScreen, isTabletScreen } = MediaQuery;

  const cityImageClassnames = classNames('city-image', {
    'city-image__small-display':
      isMobileScreen() || isPhabletScreen() || isTabletScreen()
  });

  return (
    <div className={`city ga-home-city-list-${id}`}>
      <a href={path}>
        <LazyLoad height={200} offset={[150, 150]} once>
          <figure className={cityImageClassnames}>
            <img
              src={getResourcePath(`/images/home/city-list/${id}.jpg`)}
              alt={cityName}
            />
          </figure>
        </LazyLoad>

        <div className="city-count">
          <span className="city-info-name">{rentalsInCityTrans}</span>
          <span className="city-info-homes">
            +{`${TOP_CITIES[id]} ${trans('landingpage.label.properties')}`}
          </span>
        </div>
      </a>
    </div>
  );
};

City.propTypes = {
  id: PropTypes.string.isRequired
};
export default City;
