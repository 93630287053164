const PARENT_SELECTOR = '[data-scroll-parent]';

function scrollVerticallyTo({ attribute = {}, offset = 0 }) {
  const { name, value } = attribute;

  if (typeof window !== 'undefined') {
    const element = document.querySelector(`[data-${name}=${value}]`);

    if (element) {
      const parent = document.querySelector(PARENT_SELECTOR) || window;
      const bodyParent =
        document.querySelector(PARENT_SELECTOR) || document.body;
      const bodyRect = bodyParent.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      parent.scroll({
        top: elementRect.top - bodyRect.top + offset,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}

export { scrollVerticallyTo };
